<ng-container>
    <div class="row">
        <div class="col-md-12">

            <div class="row">
                <div class="col-md-12 hdr-page">
                    Use our Powerful AI
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 hdr-sub creators">
                    to create amazing resumes
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 choose-plan">
                    Choose a plan that’s right for you
                </div>
            </div>





            <div class="col-md-12">
                <div class="row  license-cards-container ">
                    <div class="col-md-10 col-sm-9 mx-auto">
                        <div class="row justify-content-center license-cards">
                            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 single-license-card">
                                <div class="row text-center">
                                <div class="col">
                                    <span class="current-plan">{{currentPlanType === FREE ? '(Current Plan)' : '&nbsp;'}}</span>
                                </div>
                            </div>
    
                            <!-- start free card -->
                            <p-card (click)="selectOptionType('free', $event)" styleClass="{{optionType === 'free' ? 'ngx-color-selected' : 'ngx-color'}} {{isFreeCarddisabled ? 'disabled-card' : 'clickable-card'}}" header="Free" >
                                <div class="row ">
                                    <div class="col-md-12 {{optionType === 'free' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                        Ideal for scraping your Linked in profile and editing it for different job posts
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row align-items-center">
                                            <div class="col-md-auto unset-padding-right">
                                                <span class="{{optionType === 'free' ? 'card-price-section-selected' : 'card-price-section'}}">$0</span>
                                            </div>
                                            <div class="col-md-auto">
                                                <span class="optionType === 'free' ? 'card-month-section-selected' : 'card-month-section'">/ Month</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- start free button -->
                                <div class="row mt">
                                    <div class="col-md-12 text-center">
                                        <button class="btn btn-primary payment-button rounded-pill mt-4"
                                        (click)="goToFreeOption()">Get Started Now</button>
                                    </div>
                                </div>
                                <!-- end free button -->

                                <!-- start free benefits enumeration -->
                                <div class="row mt-benefits">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-check.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits">
                                            <span class="{{optionType === 'free' ? 'benefits-selected' : 'benefits'}}">
                                                Linked in Resume scraping
                                            </span>
                                        </div>
                                    </div>
                                     
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-check.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits">
                                            <span class="{{optionType === 'free' ? 'benefits-selected' : 'benefits'}}">
                                                Online editing of your Resume
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                PDF download of your Resume 
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                Several Resume formats to choose
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                Update Resume sections with GPT-4
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                One click alignment with a JD
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                Save JD align Resume
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="assets/img/item-times.png" alt="">
                                        </div>
                                        <div class="flex-grow-1 ms-3 benefits-not">
                                            <span class="">
                                                Load saved Resumes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end free benefits enumeration -->
                            </p-card>
                            <!-- end free card -->
                               
                            </div>
                            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 three-license-card ">
                                <div class="row text-center">
                                    <div class="col">
                                        <span class="current-plan">{{currentPlanType === STANDARD ? '(Current Plan)' : '&nbsp;'}}</span>
                                    </div>
                                </div>
    
                                <!-- start regular card -->
                                <p-card  (click)="selectOptionType('regular', $event)" styleClass="{{optionType === 'regular' ? 'ngx-color-selected' : 'ngx-color'}}   
                                {{isRegCarddisabled ? 'disabled-card' : 'clickable-card'}} " header="Edit with Chat GPT" >
    
                                    <div class="row">
                                        <div class="col-md-12 {{optionType === 'regular' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                            Ideal for individuals who who need advanced AI features.
                                        </div>
                                    </div>
    
                                    <div class="row">
                                        <div class="col-md-12">
    
                                            <div class="row align-items-center">
                                                <div class="col-md-auto unset-padding-right">
                                                    <span class="{{optionType === 'regular' ? 'card-price-section-selected' : 'card-price-section'}}">$1.99</span>                                                
                                                </div>
                                                <div class="col-md-auto">
                                                    <span class="optionType === 'regular' ? 'card-month-section-selected' : 'card-month-section'">/ Month</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <!-- start regular button -->
                                    <div class="row mt">
                                        <div class="col-md-12 text-center">
                                            <button class="btn btn-primary payment-button rounded-pill mt-4"
                                            (click)="redirectToPayment('standard')">Get Started Now</button>
                                        </div>
                                    </div>
                                    <!-- end regular button -->
    
                                    <!-- start standard/regular benefits enumeration -->
                                    <div class="row mt-benefits">


                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-check.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits">
                                                    <span class="{{optionType === 'regular' ? 'benefits-selected' : 'benefits'}}">
                                                        Linked in Resume scraping
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-check.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits">
                                                    <span class="{{optionType === 'regular' ? 'benefits-selected' : 'benefits'}}">
                                                        Online editing of your Resume
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-check.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits">
                                                    <span class="{{optionType === 'regular' ? 'benefits-selected' : 'benefits'}}">
                                                        PDF download of your Resume
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-check.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits">
                                                    <span class="{{optionType === 'regular' ? 'benefits-selected' : 'benefits'}}">
                                                        Several Resume formats to choose
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-check.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits">
                                                    <span class="{{optionType === 'regular' ? 'benefits-selected' : 'benefits'}}">
                                                        Update Resume sections with GPT-4
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-times.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits-not">
                                                    <span class="">
                                                        One click alignment with a JD
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex mt align-items-center">
                                                <div class="flex-shrink-0">
                                                    <img src="assets/img/item-times.png" alt="">
                                                </div>
                                                <div class="flex-grow-1 ms-3 benefits-not">
                                                    <span class="">
                                                        Load saved Resumes
                                                    </span>
                                                </div>
                                            </div>
                                    </div>
                                </p-card>
                        
                            </div>
                            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 ten-license-card ">
            
                                <div class="row text-center">
                                    <div class="col">
                                        <span class="current-plan">{{currentPlanType === PREMIUM ? '(Current Plan)' : '&nbsp;'}}</span>
                                    </div>
                                </div>
    
                                <!-- start premium card -->
                                <p-card (click)="selectOptionType('premium', $event)" styleClass="{{optionType === 'premium' ? 'ngx-color-selected' : 'ngx-color'}} clickable-card " header="JD Alignment" >
    
                                    <div class="row">
                                        <div class="col-md-12 {{optionType === 'premium' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                            Ideal for individuals who want to use AI and align their resumes to multiple jobs.
                                        </div>
                                    </div>
    
                                    <div class="row">
                                        <div class="col-md-12">
    
                                            <div class="row align-items-center">
                                                <div class="col-md-auto unset-padding-right">
                                                    <span class="{{optionType === 'premium' ? 'card-price-section-selected' : 'card-price-section'}}">$4.99</span>                                                
                                                </div>
                                                <div class="col-md-auto">
                                                    <span class="optionType === 'premium' ? 'card-month-section-selected' : 'card-month-section'">/ Month</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <!-- start premium button -->
                                    <div class="row mt">
                                        <div class="col-md-12 text-center">
                                            <button class="btn btn-primary payment-button rounded-pill mt-4"
                                            (click)="redirectToPayment('premium')">Get Started Now</button>
                                        </div>
                                    </div>
                                    <!-- end premium button -->
    
                                    <!-- start premium benefits enumeration -->
                                    <div class="row mt-benefits">

                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Linked in Resume scraping
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Online editing of your Resume
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    PDF download of your Resume
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Several Resume formats to choose
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Update Resume sections with GPT-4
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    One click alignment with a JD
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Save JD align Resume
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mt align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="assets/img/item-check.png" alt="">
                                            </div>
                                            <div class="flex-grow-1 ms-3 benefits">
                                                <span class="{{optionType === 'premium' ? 'benefits-selected' : 'benefits'}}">
                                                    Load saved Resumes
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end premium benefits enumeration -->
    
    
    
                                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                                        quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p> -->
                                </p-card>
            
                               
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>



            <!-- start cards -->
          
            <!-- end cards -->

            <!-- start cancel button -->
            <div class="row mt-benefits">
                <div class="col-md-12 text-center">
                    <button *ngIf="currentPlanType !== FREE" [disabled]="polling" (click)="confirmCancelSubscription()" class="btn btn-danger cancel-button rounded-pill">Cancel
                        <div *ngIf="polling" class="spinner-border spinner-border-sm" role="status">
                        </div>
                    </button>
                </div>
            </div>
            <!-- start cancel button -->

        </div>
    </div>
</ng-container>

<!-- start enter details dialog -->

<form [formGroup]="enterDetailForm" (ngSubmit)="onSubmit()">
    <p-dialog  [(visible)]="enterDetailsModal" [draggable]="true" [closable]="false" [showHeader]="true"
            [blockScroll]="true" [modal]="true" 
            [styleClass]="displayDialog ? 'responsive-dialog' : ''"
            *ngIf="enterDetailsModal" #eDetailsModal>

        <p-header>
            <div class="row">
                <div class="col-md-12 text-left">
                <!-- <h5 class="modal-title" id="exampleModalLabel">{{ !experienceAddMode ? 'Edit' :  'Add'}} Experience</h5> -->
                </div>
            </div>
        </p-header>

        <div class="row">
            <div class="col-12 text-center">
                <span class="ent-header">Enter Your Details</span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-12">

                <div class="row">
                <div class="col-md-10">
                    <!-- <form [formGroup]="enterDetailForm" (ngSubmit)="onSubmit()"> -->

                    <!-- start first name -->
                    <div class="form-group">
                        <label for="firstName" class="mb form-label">First Name</label>
                        <input id="firstName" placeholder="Add your first name here.." type="text" class="form-control curved" formControlName="firstName">
                    </div>

                    <div class="text-danger mt-2" *ngIf="submitted && (enterDetailForm.controls['firstName'].invalid || 
                        (enterDetailForm.controls['firstName'].errors && enterDetailForm.controls['firstName'].errors['required'])
                    )">
                        First Name is required
                    </div>
                    <!-- end first name -->

                    <!-- start last name -->
                    <div class="form-group">
                        <label for="lastName" class="mt-3 mb form-label">Last Name</label>
                        <input id="lastName" placeholder="Add your last name here.." type="text" class="form-control curved" formControlName="lastName">
                    </div>
                    <div class="text-danger mt-2" *ngIf="submitted && (enterDetailForm.controls['lastName'].invalid || 
                        (enterDetailForm.controls['lastName'].errors && enterDetailForm.controls['lastName'].errors['required'])
                    )">
                        Last Name is required
                    </div>
                    <!-- end last name -->

                    <!-- start email -->
                    <div class="form-group">
                        <label for="email" class="mt-3 mb form-label">Email</label>
                            <input id="email" placeholder="Add your email.." type="text" class="form-control curved" formControlName="email">
                        </div>
                        <div class="text-danger mt-2" *ngIf="submitted && (enterDetailForm.controls['lastName'].invalid || 
                        (enterDetailForm.controls['lastName'].errors && enterDetailForm.controls['lastName'].errors['required'])
                        )">
                        Last Name is required
                        </div>

                        <div class="text-danger mt-2" *ngIf="submitted && (
                        (enterDetailForm.controls['email'].errors && enterDetailForm.controls['email'].errors['invalidEmail'])
                        )">
                            Email format is invalid
                    </div>
                    <!-- end email -->

                    <!-- start phone -->
                    <div class="form-group">
                        <label for="phone" class="mt-3 mb form-label">Phone Number</label>
                            <input id="phone" placeholder="Add your phone number.." type="text" class="form-control curved" formControlName="phone">
                    </div>
                    <div class="text-danger mt-2" *ngIf="submitted && (enterDetailForm.controls['lastName'].invalid || 
                    (enterDetailForm.controls['phone'].errors && enterDetailForm.controls['phone'].errors['required'])
                    )">
                    Phone is required
                    </div>
                    <!-- end phone -->
                </div>
                </div>
            </div>
        </div>

        <p-footer>
            <div class="row">
                <div class="col-md-12">
                
                <div  class="row">

                    <div class="col-md-12 text-center">
                    <button type="submit" class="btn btn-primary enter-detail-button rounded-pill">Submit</button>
                    </div>

                </div>

                </div>

            </div>
        </p-footer>
    </p-dialog>

</form>
<!-- end enter details dialog -->

<p-confirmDialog key="confirmDelSubscriptionDialog" header="Confirmation" 
icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

