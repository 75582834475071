import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppUser, Client, Profile, ProfileContact, ProfileCourse, ProfileDate, ProfileEducation, ProfileExperience, ProfileExperienceGPT, ProfileProject, ProfilePublication, SubscriptionPlan } from '../model/Userprofile';
import { LocalStorageService } from '../core/local-storage.service';
import { AytHttpParams } from '../helpers/http-config';
import { UserService } from '../core/user.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'app/helpers/data.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Constants } from 'app/helpers/app-settings';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { DomainValidator } from 'app/helpers/xcv-validator';

@Component({
    selector: 'app-resume-preview',
    templateUrl: './resume-preview.component.html',
    styleUrls: ['./resume-preview.component.scss']
})
export class ResumePreviewComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    message!: string;

    @Input()
    vanity_name_parent!: string;
    @Input()
    title_parent!: string;
    @Input()
    recruiter_email_parent!: string;

    @Output() messageEvent = new EventEmitter<string>();
    @Output() templateSelectedEvent = new EventEmitter<string>();    
    @Output() templateRemoveEvent = new EventEmitter<string>();    
    @Output() saveExecutedEvent = new EventEmitter<string>();    
    @Output() previewVisibleEvent = new EventEmitter<string>();    
    @Output() clientEmailEvent = new EventEmitter<string>();    
    @Output() resumeRetrievedEvent = new EventEmitter<string>();    
    @Output() clientRetrievedEvent = new EventEmitter<string>();    
    
    private previewVisibleChild1 = true;
    private hasRefreshed = false;



    public showCarouselModal = false;
    public products: any[] = [
        {
            id: '1000',
            code: 'basic',
            name: 'Basic',
            image: '/assets/img/thumbnails/template1.png',
        },
        {
            id: '1001',
            code: 'classic',
            name: 'Classic',
            image: '/assets/img/thumbnails/template3.png',
        },
        {
            id: '1002',
            code: 'modern',
            name: 'Modern',
            image: '/assets/img/thumbnails/template2.png',
        },
        {
            id: '1003',
            code: 'vintage',
            name: 'Vintage',
            image: '/assets/img/thumbnails/template4.png',
        },
        {
            id: '1004',
            code: 'simple',
            name: 'Simple',
            image: '/assets/img/thumbnails/template5.png',
        },
        {
            id: '1005',
            code: 'casual',
            name: 'Casual',
            image: '/assets/img/thumbnails/template6.png',
        },
        {
            id: '1006',
            code: 'chrono',
            name: 'Chrono',
            image: '/assets/img/thumbnails/template7.png',
        },
        {
            id: '1007',
            code: 'horizontal',
            name: 'Horizontal',
            image: '/assets/img/thumbnails/template8.png',
        },
        {
            id: '1007',
            code: 'luxe',
            name: 'Luxe',
            image: '/assets/img/thumbnails/template9.png',
        },
        {
            id: '1008',
            code: 'professional',
            name: 'Professional',
            image: '/assets/img/thumbnails/template10.png',
        },
        {
            id: '1008',
            code: 'elegant',
            name: 'Elegant',
            image: '/assets/img/thumbnails/template11.png',
        },
         {
            id: '1009',
            code: 'chronicle',
            name: 'Chronicle',
            image: '/assets/img/thumbnails/template12.png',
        },
        {
            id: '1010',
            code: 'smart',
            name: 'Smart',
            image: '/assets/img/thumbnails/template13.png',
        },
        {
            id: '1010',
            code: 'majestic',
            name: 'Majestic',
            image: '/assets/img/thumbnails/template14.png',
        },
        {
            id: '1010',
            code: 'windsor',
            name: 'Windsor',
            image: '/assets/img/thumbnails/template15.png',
        }
    ];

    public responsiveOptions: any[] = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }        
    ];




    public public_key: any;
    public resumeForm !: FormGroup;
    public resumeDetails: any = {
        "Bio": {
            "FirstName": "Mujahid",
            "LastName": "Sultan",
            "Title": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
            "About": "I am a serial entrepreneur, an expert in solving complex business problems in health care, finance, and cloud computing using artificial intelligence and machine learning. I have architected and designed high throughput and scalable systems.  I am a senior software engineer with vast experience in machine learning, pattern recognition, deep learning, NLP, time-series forecasting, and cloud-native microservices-based developments. \n\nI am the author of novel frameworks and patterns for architecting today's modern enterprise, my current research focus is a) developing a robust clustering method and b) improving learning from imbalanced datasets on graph-based deep learning backends.\n \nI have authored in high-impact Machine Learning, Artificial Intelligence, Data Visualization, Genetics and Drug Discovery for Cancer, Requirements Engineering, and Enterprise Architecture journals. My publications can be found at https://orcid.org/0000-0001-6721-4044",
            "Industry": "Information Technology & Services",
            "Location": "Canada",
            "Country": "Canada",
            "Languages": [
                {
                    "name": "English"
                }
            ]
        },
        "Skills": [
            "Machine Learning",
            "Python (Programming Language)",
            "Information Technology",
            "Deep Learning",
            "Solution Architecture",
            "Enterprise Architecture",
            "Project Management",
            "Data Analysis",
            "SDLC",
            "Business Analysis",
            "Requirements Analysis",
            "IT Strategy",
            "Data Center",
            "Program Management",
            "IT Management",
            "Software Project Management",
            "Change Management",
            "Business Process Design",
            "Software Development Life Cycle (SDLC)",
            "Consulting",
            "Infrastructure",
            "Architecture",
            "Business Process",
            "Management Consulting",
            "Strategy",
            "Agile Methodologies",
            "Artificial Intelligence",
            "Statistical Modeling",
            "Statistical Data Analysis",
            "Data Science",
            "Artificial Intelligence (AI)",
            "Big Data",
            "Matlab",
            "R",
            "Deep Neural Networks",
            "MapReduce",
            "Hadoop",
            "MongoDB",
            "TensorFlow",
            "Team Leadership",
            "Artificial Neural Networks",
            "Predictive Modeling",
            "Predictive Analytics",
            "Governance",
            "Natural Language Processing (NLP)",
            "Clustering",
            "Classification",
            "Parallel Computing",
            "Parallel Programming",
            "Scikit-Learn"
        ],
        "Education": {
            "timePeriod": {
                "endDate": {
                    "month": 8,
                    "year": 2022
                },
                "startDate": {
                    "month": 9,
                    "year": 2015
                }
            },
            "degreeName": "Ph.D.",
            "schoolName": "Ryerson University",
            "fieldOfStudy": "Computer Science"
        },
        "Experience": {
            "companyName": "MLSoft",
            "timePeriod": {
                "startDate": {
                    "month": 12,
                    "year": 2020
                }
            },
            "company": {
                "employeeCountRange": {
                    "start": 2,
                    "end": 10
                },
                "industries": [
                    "Computer Software"
                ]
            },
            "title": "Co-Founder and President",
            "Publications": [
                {
                    "date": {
                        "month": 12,
                        "year": 2020,
                        "day": 1
                    },
                    "name": "Probabilistic Partitive Partitioning (PPP)",
                    "publisher": "https://arxiv.org/abs/2003.04372",
                    "description": "Clustering is a NP-hard problem. Thus, no optimal algorithm exists, heuristics are applied to cluster the data. Heuristics can be very resource-intensive, if not applied properly. For substantially large data sets computational efficiencies can be achieved by reducing the input space if a minimal loss of information can be achieved. Clustering algorithms, in general, face two common problems: 1) these converge to different settings with different initial conditions and; 2) the number of clusters has to be arbitrarily decided beforehand. This problem has become critical in the realm of big data. Recently, clustering algorithms have emerged which can speedup computations using parallel processing over the grid but face the aforementioned problems. Goals: Our goals are to find methods to cluster data which: 1) guarantee convergence to the same settings irrespective of the initial conditions; 2) eliminate the need to establish the number of clusters beforehand, and 3) can be applied to cluster large datasets. Methods: We introduce a method that combines probabilistic and combinatorial clustering methods to produce repeatable and compact clusters that are not sensitive to initial conditions. This method harnesses the power of k-means (a combinatorial clustering method) to cluster/partition very large dimensional datasets and uses the Gaussian Mixture Model (a probabilistic clustering method) to validate the k-means partitions. Results: We show that this method produces very compact clusters that are not sensitive to initial conditions. This method can be used to identify the most 'separable' set in a dataset which increases the 'clusterability' of a dataset. This method also eliminates the need to specify the number of clusters in advance. ",
                    "url": "https://arxiv.org/abs/2003.04372",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        }
                    ]
                },
                {
                    "date": {
                        "month": 10,
                        "year": 2020,
                        "day": 11
                    },
                    "name": "Designing knowledge plane to optimize leaf and spine data center",
                    "publisher": "2020 IEEE 13th International Conference on Cloud Computing (CLOUD)",
                    "description": "Abstract—In the last few decades, data center architecture evolved from the traditional client-server to access-aggregation-core architectures. Recently there is a new shift in the data center architecture due to the increasing need for low latency and high throughput between server-to-server communications, load balancing and, loop-free environment. This new architecture, known as leaf and spine architecture, provides low latency and minimum packet loss by enabling the addition and deletion of network nodes on demand. Network nodes can be added or deleted from the network based on network statistics like link speed, packet loss, latency, and throughput.\n\nWith the maturity of Open Virtual Switch (OvS) and Open-Flow based Software Defined Network (SDN) controllers, network automation through programmatic extensions has become possible based on network statistics. Separation of control plane and data plane has enabled automated management of network and Machine Learning (ML) can be applied to learn and optimize the network.\n\nIn this publication, we propose the design of an ML-based approach to gather network statistics and build a knowledge plane. We demonstrate that this knowledge plane enables data center optimization using southbound APIs and SDN controllers. We describe the design components of this approach - using a network simulator and show that it can maintain the historical patterns of network statistics to predict future growth or decline.",
                    "url": "https://arxiv.org/abs/2009.08492",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        }
                    ]
                },
                {
                    "date": {
                        "month": 9,
                        "year": 2020,
                        "day": 1
                    },
                    "name": "Linking Stakeholders' Viewpoint Concerns and Microservices-based Architecture",
                    "publisher": "arXiv",
                    "description": "Widespread adoption of agile project management, independent delivery with microservices, and automated deployment with DevOps has tremendously speedup the systems development. The real game-changer is continuous integration (CI), continuous delivery, and continuous deployment (CD). Organizations can do multiple releases a day, shortening the test, release, and deployment cycles from weeks to minutes.\nMaturity of container technologies like Docker and container orchestration platforms like Kubernetes has promoted microservices architecture, especially in the cloud-native developments. Various tools are available for setting up CI/CD pipelines. Organizations are moving away from monolith applications and moving towards microservices-based architectures. Organizations can quickly accumulate hundreds of such microservices accessible via application programming interfaces (APIs).\nThe primary purpose of these modern methodologies is agility, speed, and reusability. While DevOps offers speed and time to market, agility and reusability may not be guaranteed unless microservices and API's are linked to enterprise-wide stakeholders' needs. The link between business needs and microservices/APIs is not well captured nor adequately defined.\nIn this publication, we describe a structured method to create a logical link among APIs and microservices-based agile developments with enterprise stakeholders' needs and viewpoint concerns. This method enables capturing and documenting enterprise-wide stakeholders' needs, whether these are business owners, planners (product owners, architects), designers (developers, DevOps engineers), or the partners and subscribers of an enterprise. ",
                    "url": "https://arxiv.org/abs/2009.01702",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        }
                    ]
                },
                {
                    "date": {
                        "month": 1,
                        "year": 2018,
                        "day": 29
                    },
                    "name": "Ordering stakeholder viewpoint concerns for holistic enterprise architecture: the W6H framework",
                    "publisher": "SAC '18: Proceedings of the 33rd Annual ACM Symposium on Applied Computing",
                    "description": "Context: Enterprise Architecture (EA) is a discipline which has evolved to structure the business and its alignment with the IT systems. Most of the enterprise architecture frameworks, especially Zachman framework, focus on describing the enterprise from six viewpoint perspectives of the stakeholders. These six perspectives are based on English language interrogatives what, where, who, when, why, and how (thus the term W5H). W5H to describe an event or incident is common in journalism, however, it is difficult to \"fit\" EA into the \"universe\" of events, leading to difficulties in creation and evolution of EA. Moreover, the ordering in which interrogatives should be answered and viewpoints arranged is not defined in the existing EA frameworks, making the order and inter-dependence difficult to decide.\n\nIn this paper, our goals are to 1) assess if W5H is sufficient to describe EA of today's rapidly evolving enterprise, and 2) explore the ordering and precedence among the viewpoint concerns, a major problem faced by the EA practitioners today.\n\nMethod: We achieve our goals by bringing tools from the linguistics, focusing on the full set of English language interrogatives to describe viewpoint concerns and the inter-relationships and dependencies among these.",
                    "url": "https://dl.acm.org/doi/10.1145/3167132.3167137",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        }
                    ]
                },
                {
                    "date": {
                        "month": 8,
                        "year": 2015,
                        "day": 25
                    },
                    "name": "Ordering Interrogative Questions for Effective Requirements Engineering: The W6H Pattern",
                    "publisher": "IEEE : 5ht International Workshop on Requirements Patterns (RPa'15) @ 23rd International Conference on Requirements Engineering (RE'15)",
                    "description": "Abstract—Requirements elicitation and requirements analysis are important practices of Requirements Engineering. Elicitation techniques, such as interviews and questionnaires, rely on formulating interrogative questions and asking these in a proper order to maximize the accuracy of the information being gathered. Information gathered during requirements elicitation then has to be interpreted, analyzed, and validated. Requirements analysis involves analyzing the problem and solutions spaces. In this paper, we describe a method to formulate interrogative questions for effective requirements elicitation based on the lexical and semantic principles of the English language interrogatives, and propose a pattern to organize stakeholder viewpoint concerns for better requirements analysis. This helps requirements engineer thoroughly describe problem and solutions spaces. \nMost of the previous requirements elicitation studies included six out of the seven English language interrogatives ‘what’, ‘where’, ‘when’, ‘who’, ‘why’, and ‘how’ (denoted by W5H) and did not propose any order in the interrogatives. We show that extending the set of six interrogatives with ‘which’ (denoted by W6H) improves the generation and formulation of questions for requirements elicitation and facilitates better requirements analysis via arranging stakeholder views. We discuss the interdependencies among interrogatives (for requirements engineer to consider while eliciting the requirements) and suggest an order for the set of W6H interrogatives. The proposed W6H-based reusable pattern also aids requirements engineer in organizing viewpoint concerns of stakeholders, making this pattern an effective tool for requirements analysis.\n",
                    "url": "http://ieeexplore.ieee.org/xpl/articleDetails.jsp?arnumber=7407731&filter%3DAND%28p_IS_Number%3A7407726%29",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        },
                        {
                            "name": "Andriy"
                        }
                    ]
                },
                {
                    "date": {
                        "month": 2,
                        "year": 2002
                    },
                    "name": "Binary tree-structured vector quantization approach to clustering and visualizing microarray data",
                    "publisher": "Bioinformatics",
                    "description": "Motivation: With the increasing number of gene expression databases, the need for more powerful analysis and visualization tools is growing. Many techniques have successfully been applied to unravel latent similarities among genes and/or experiments. Most of the current systems for microarray data analysis use statistical methods, hierarchical clustering, self-organizing maps, support vector machines, or k-means clustering to organize genes or experiments into ‘meaningful’ groups. Without prior explicit bias almost all of these clustering methods applied to gene expression data not only produce different results, but may also produce clusters with little or no biological relevance. Of these methods, agglomerative hierarchical clustering has been the most widely applied, although many limitations have been identified. \r\nResults: Starting with a systematic comparison of the underlying theories behind clustering approaches, we have devised a technique that combines tree-structured vector quantization and partitive k-means clustering (BTSVQ). This hybrid technique has revealed clinically relevant clusters in three large publicly available data sets. In contrast to existing systems, our approach is less sensitive to data preprocessing and data normalization. In addition, the clustering results produced by the technique have strong similarities to those of self-organizing maps (SOMs). We discuss the advantages and the mathematical reasoning behind our approach. \r\nAvailability: The BTSVQ system is implemented in Matlab R12 using the SOM toolbox for the visualization and preprocessing of the data http://www.cis.hut.fi/projects/somtoolbox/ BTSVQ is available for non-commercial use http://www.uhnres.utoronto.ca/ta3/BTSVQ",
                    "url": "http://bioinformatics.oxfordjournals.org/content/18/suppl_1/S111.short",
                    "authors": [
                        {
                            "member": {
                                "firstName": "Mujahid",
                                "lastName": "Sultan",
                                "dashEntityUrn": "urn:li:fsd_profile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "occupation": "Founder @ MLSoft.ai | PMP, CISSP, PhD",
                                "objectUrn": "urn:li:member:12994949",
                                "entityUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0",
                                "backgroundImage": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "200_800/0/1556250093177?e=1691625600&v=beta&t=BVW8ovZJh1Vw05leq5Zw6f77JbCHOdIDnUkZo-fLwt8",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 1400,
                                                "fileIdentifyingUrlPathSegment": "350_1400/0/1556250093177?e=1691625600&v=beta&t=hhNmOT6X57gv4sW6eWQesc6sTmjbRuFlHvyuFgcme88",
                                                "expiresAt": 1691625600000,
                                                "height": 350
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/C4E16AQEmPipJdSidpA/profile-displaybackgroundimage-shrink_"
                                    }
                                },
                                "publicIdentifier": "mujahidsultan",
                                "picture": {
                                    "com.linkedin.common.VectorImage": {
                                        "artifacts": [
                                            {
                                                "width": 100,
                                                "fileIdentifyingUrlPathSegment": "100_100/0/1673926293105?e=1691625600&v=beta&t=u5XrThSITX08Xb2iri0e1PRqHtoHJHhUkL31QdoW958",
                                                "expiresAt": 1691625600000,
                                                "height": 100
                                            },
                                            {
                                                "width": 200,
                                                "fileIdentifyingUrlPathSegment": "200_200/0/1673926293105?e=1691625600&v=beta&t=qEsbgP6atfvRNSWmM7pHiZyg8eWQ5VwR5IxYpgqGYdY",
                                                "expiresAt": 1691625600000,
                                                "height": 200
                                            },
                                            {
                                                "width": 400,
                                                "fileIdentifyingUrlPathSegment": "400_400/0/1673926293105?e=1691625600&v=beta&t=XvcTtIKlj-2hX6K3H3Y7EMPNiVucREXd5BlT9ZNjOiA",
                                                "expiresAt": 1691625600000,
                                                "height": 400
                                            },
                                            {
                                                "width": 800,
                                                "fileIdentifyingUrlPathSegment": "800_800/0/1673926293105?e=1691625600&v=beta&t=egXVYrNcB08hb72fdKj2hIeNTmqZh-aMOrcG-jcsrH8",
                                                "expiresAt": 1691625600000,
                                                "height": 800
                                            }
                                        ],
                                        "rootUrl": "https://media.licdn.com/dms/image/D5603AQHvgMWr3sa5iQ/profile-displayphoto-shrink_"
                                    }
                                },
                                "trackingId": "aieVkvGSSc6IURV5F/lLOA=="
                            },
                            "profileUrn": "urn:li:fs_miniProfile:ACoAAADGSYUBIndmP8azE52pC3iicgsforweIW0"
                        }
                    ]
                }
            ],
            "Certifications": [
                {
                    "authority": "ISACA",
                    "name": "Certified Information Security Manager® (CISM)",
                    "company": {
                        "objectUrn": "urn:li:company:21062",
                        "entityUrn": "urn:li:fs_miniCompany:21062",
                        "name": "ISACA",
                        "showcase": false,
                        "active": true,
                        "logo": {
                            "com.linkedin.common.VectorImage": {
                                "artifacts": [
                                    {
                                        "width": 200,
                                        "fileIdentifyingUrlPathSegment": "200_200/0/1580742431409?e=1694044800&v=beta&t=IXFQxlXyqlpF_XA3PZpU1schYCvfERBNdPpHCmsMWlg",
                                        "expiresAt": 1694044800000,
                                        "height": 200
                                    },
                                    {
                                        "width": 100,
                                        "fileIdentifyingUrlPathSegment": "100_100/0/1580742431409?e=1694044800&v=beta&t=jph4ccWSKJVgdu8jUB69R5M6_KjOp_6EIcDC-RZMskQ",
                                        "expiresAt": 1694044800000,
                                        "height": 100
                                    },
                                    {
                                        "width": 400,
                                        "fileIdentifyingUrlPathSegment": "400_400/0/1580742431409?e=1694044800&v=beta&t=zhcoUf4Bl_poleRzqbdIwn_V7LIFati5HRnMUjlv74Y",
                                        "expiresAt": 1694044800000,
                                        "height": 400
                                    }
                                ],
                                "rootUrl": "https://media.licdn.com/dms/image/C4E0BAQEbgaF0IMoyIw/company-logo_"
                            }
                        },
                        "universalName": "isaca",
                        "dashCompanyUrn": "urn:li:fsd_company:21062",
                        "trackingId": "NocRCJsQQD+NraalWI3Z2g=="
                    },
                    "displaySource": "youracclaim.com",
                    "companyUrn": "urn:li:fs_miniCompany:21062",
                    "url": "https://www.youracclaim.com/badges/d81a0ce5-1cdf-4362-8ad4-00927bef6d05"
                },
                {
                    "authority": "Project Management Institute",
                    "name": "Project Management Professional",
                    "licenseNumber": "1617767",
                    "company": {
                        "objectUrn": "urn:li:company:11352",
                        "entityUrn": "urn:li:fs_miniCompany:11352",
                        "name": "Project Management Institute",
                        "showcase": false,
                        "active": true,
                        "logo": {
                            "com.linkedin.common.VectorImage": {
                                "artifacts": [
                                    {
                                        "width": 200,
                                        "fileIdentifyingUrlPathSegment": "200_200/0/1570240591021?e=1694044800&v=beta&t=zYub8TT-WsUuUmaC4QYXIw_ot3aw2IO0iTy_FtyrLXg",
                                        "expiresAt": 1694044800000,
                                        "height": 200
                                    },
                                    {
                                        "width": 100,
                                        "fileIdentifyingUrlPathSegment": "100_100/0/1570240591021?e=1694044800&v=beta&t=jx49uqgt14Q7xAyYtANz596Lw5CYbFIFuLJPfr5NdJE",
                                        "expiresAt": 1694044800000,
                                        "height": 100
                                    },
                                    {
                                        "width": 400,
                                        "fileIdentifyingUrlPathSegment": "400_400/0/1570240591021?e=1694044800&v=beta&t=t3q1t4-6OdCsPUhHAgt3Tqha2PSErhgMDasIFeC0F6w",
                                        "expiresAt": 1694044800000,
                                        "height": 400
                                    }
                                ],
                                "rootUrl": "https://media.licdn.com/dms/image/C4E0BAQFYYvw5asgdjQ/company-logo_"
                            }
                        },
                        "universalName": "projectmanagementinstitute",
                        "dashCompanyUrn": "urn:li:fsd_company:11352",
                        "trackingId": "chWLWgDWTgC5uQe09xYqPA=="
                    },
                    "companyUrn": "urn:li:fs_miniCompany:11352"
                },
                {
                    "authority": "ISC2 Ottawa Chapter",
                    "name": "Certified Information Systems Security Professional (CISSP)",
                    "licenseNumber": "482881",
                    "company": {
                        "objectUrn": "urn:li:company:28996118",
                        "entityUrn": "urn:li:fs_miniCompany:28996118",
                        "name": "ISC2 Ottawa Chapter",
                        "showcase": false,
                        "active": true,
                        "logo": {
                            "com.linkedin.common.VectorImage": {
                                "artifacts": [
                                    {
                                        "width": 200,
                                        "fileIdentifyingUrlPathSegment": "200_200/0/1527816037575?e=1694044800&v=beta&t=KYLG4vwJOuU2-SHNCdsKsbePdUnojorpEKT3LWBeGDs",
                                        "expiresAt": 1694044800000,
                                        "height": 200
                                    },
                                    {
                                        "width": 100,
                                        "fileIdentifyingUrlPathSegment": "100_100/0/1527816037575?e=1694044800&v=beta&t=5Z1sdmXsELYWbotWYFFEWf7-qzvKCxfX4JTuOD4vkK8",
                                        "expiresAt": 1694044800000,
                                        "height": 100
                                    },
                                    {
                                        "width": 400,
                                        "fileIdentifyingUrlPathSegment": "400_400/0/1527816037575?e=1694044800&v=beta&t=ikVFWlNNMfkxKlDsnHEhaokg-8MQfSLzXdJbtw25mNs",
                                        "expiresAt": 1694044800000,
                                        "height": 400
                                    }
                                ],
                                "rootUrl": "https://media.licdn.com/dms/image/C4E0BAQFEQpGUSu_-Ww/company-logo_"
                            }
                        },
                        "universalName": "isc2-ottawa-chapter",
                        "dashCompanyUrn": "urn:li:fsd_company:28996118",
                        "trackingId": "JikGh46tTiyoVy/+SKqdQQ=="
                    },
                    "companyUrn": "urn:li:fs_miniCompany:28996118"
                }
            ],
            "Volunteer": [],
            "Honors": [],
            "Projects": []
        },
        "Contact": {
            "email_address": "m.sultan@utoronto.ca",
            "websites": [
                {
                    "url": "https://www.mlsoft.ai",
                    "label": "COMPANY"
                }
            ],
            "twitter": [
                {
                    "name": "SultanMujahid",
                    "credentialId": "urn:li:member:12994949;381855782"
                }
            ],
            "birthdate": null,
            "ims": null,
            "phone_numbers": []
        }
    };
    
    public bio: any;
    public contact: ProfileContact = new ProfileContact();
    public expinfo: any;
    public skillsinfo: any;
    public langinfo: any;
    public certificateinfo:any;
    public isEditexperience: boolean = false;
    public isEditskills: boolean = false;
    public isEditlanguages:boolean = false;
    public isEditcourses:boolean = false;
    public isEditpublications:boolean = false;
    public isEditPhone: boolean = false;
    public loading = false;
    public updating = false;
    public gptGenerating = false;
    public vanity_name = '';
    public profile: Profile = new Profile();
    public profileOrig: Profile = new Profile();
    public profileExperience: ProfileExperience[] = [];
    public phoneSection: any = '';
    public isEditCountry: boolean = false;
    public isEditlanguage: boolean = false;
    public isSkills: boolean = false;
    public isEditName: boolean = false;
    public isEditTitle: boolean = false;
    public isEditAbout:boolean = false;
    public showModal:boolean = false;
    public gptrevisedData!: any;
    public revised = {
        about: "I am a serial entrepreneur, an expert in solving complex business problems in health care, finance, and cloud computing using artificial intelligence and machine learning. I have architected and designed high throughput and scalable systems.  I am a senior software engineer with vast experience in machine learning, pattern recognition, deep learning, NLP, time-series forecasting, and cloud-native microservices-based developments. \n\nI am the author of novel frameworks and patterns for architecting today's modern enterprise, my current research focus is a) developing a robust clustering method and b) improving learning from imbalanced datasets on graph-based deep learning backends.\n \nI have authored in high-impact Machine Learning, Artificial Intelligence, Data Visualization, Genetics and Drug Discovery for Cancer, Requirements Engineering, and Enterprise Architecture journals. My publications can be found at https://orcid.org/0000-0001-6721-4044\n\nGenerated chat info",
        profile: "banner adverts and high-impact adverts in the advertising industry.test 123",
        revisedprofile: " East London. I have over two and a half years of agency experience developing websites",
        skills: [
          "Java",
          "Kubernetes"
        ],
        experience: [
          {
            "name": "Freelance  Front-end Developer",
            "year": "February 2018-Present",
            "desc": "Web design and development, logo animation, SEO, content creation & photography."
          }],
        education: [
          {
            "name": "Ravensbourne University London-Web Media BA",
            "year": "2012-2015",
            "desc": "First-class honours degree."
          }],
        details: {
          "address": "East London, UK",
          "contact": "012 345 67890",
          "email": "example@steset.com"
        },
        tools: [
          "Photoshop",
          "dreamviwer",
        ]
    }
    
    public payloadV: any;
    public IsmodelShow:boolean = false;
    public gptModal = false;
    public selectedSection = '';
    public selectedExpId: number = 0;
    public selectedTitle: string = '';
    public selectedCompny: string = '';
    public visible = false;
    public resumeListng: any[] = [];
    public selectedResume: any;
    public jd_id: string = 'None';
    public jd_title: string = '';
    public subcriptionPlan: SubscriptionPlan = {};
    public mode = Constants.FREE;
    public addExperienceModal = false;
    public addPublicationModal = false;
    public addCourseModal = false;
    public addEducationModal = false;
    public addProjectModal = false;
    public sectionsModal = false;
    public experienceForm !: FormGroup;
    public publicationForm !: FormGroup;
    public courseForm !: FormGroup;
    public educationForm !: FormGroup;
    public projectForm !: FormGroup;
    public submitted = false;
    public current = false;
    public hasPublishOn = true;
    public hasStartsAt = true;
    public startsAt: Date = new Date();
    public endsAt: Date = new Date();
    public published_on: Date = new Date();
    public selectedExperience: ProfileExperience = new ProfileExperience();
    public selectedPublication: ProfilePublication = new ProfileExperience();
    public selectedCourse: ProfileCourse = new ProfileCourse();
    public selectedEducation: ProfileEducation = new ProfileEducation();
    public selectedProject: ProfileProject = new ProfileProject();
    public displayDialog: boolean = false;
    public isSmallScreen = false;
    public experienceAddMode = false;
    public publicationAddMode = false;
    public courseAddMode = false;
    public educationAddMode = false;
    public projectAddMode = false;
    public aboutUndoTriggered = false;
    public initialLoadAboutForCurrent = true;
    public initialLoadExperience = true;
    public initialLoadExperienceForCurrent = true;
    public client: Client = new Client();
    public showContactsModal:boolean = false;
    public contactsTypeList: any[] = [];
    public selectedContactType: any = {};

    public selectedEmail = '';
    public emails: string[] = [];
    public newEmailArray: string[] = [];
    public newEmail = '';

    public selectedPhone = '';
    public phones: string[] = [];
    public newPhoneArray: string[] = [];
    public newPhone = '';
    public userType = Constants.CLIENT;
    public appUser: AppUser = {};
    public title = '';
    public recruiter_email = '';
    public deleting = false;
    public experienceSort = Constants.DESC;
    public executeSaveProfile = false;
    public goJD = false;
    public selectTemplateAction = true;
    public removeTemplateAction = true;
    public hidePreviewAction = true;
    public templateSelected = false;

    public coursesVisible = true;
    public projectsVisible = true;
    public publicationsVisible = true;
    public experienceVisible = true;
    public skillsVisible = true;
    public languagesVisible = true;
    public educationVisible = true;
    public certificationsVisible = true;
    public startprofile = false;
    public contactnoVisible = true;
    public twitterVisible = true;
    public lnkdinVisible = true;
    public emailVisible = true;

    @ViewChild('acc', { static: false })
    acc!: ElementRef<any>;    

    private subscription!: Subscription;

    constructor(private http: HttpClient, 
        private localStorageService: LocalStorageService,
        private userservice: UserService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,            
        private datePipe: DatePipe,
        private dataService: DataService,
        private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder,) {

            this.subscription = this.dataService.getHidePreview().subscribe(data => {
                this.hidePreviewAction = (/true/i).test(data);
                if (this.hidePreviewAction) {
                  // hide this preview component
                  this.togglePreviewSide();
                  this.hidePreviewAction = false;
                }
            });      

            this.subscription = this.dataService.getRemoveTemplate().subscribe(data => {
                this.removeTemplateAction = (/true/i).test(data);
                if (this.removeTemplateAction) {
                  // remove the template
                  this.removeTemplate();
                  this.removeTemplateAction = false;
                }
            });      

            this.subscription = this.dataService.getSelectTemplate().subscribe(data => {
                this.selectTemplateAction = (/true/i).test(data);
                if (this.selectTemplateAction) {
                  // call the dialog box
                  this.openSelecTemplateModal();
                  this.selectTemplateAction = false;
                }
            });      
      
            this.subscription = this.dataService.getGoToJDAlign().subscribe(data => {
                this.goJD = (/true/i).test(data);
                if (this.goJD) {
                  console.log('TRIGGERING GO TO JD ALIGN');
                  this.gotoJDAlign();
                  this.goJD = false;
                }
            });      
            
            this.subscription = this.dataService.getPreviewSave().subscribe(data => {


                this.executeSaveProfile = (/true/i).test(data);
                if (this.executeSaveProfile) {
                  console.log('TRIGGERING SAVE OF PROFILE');
                  this.updateProfile(false);
                } else {
                  this.executeSaveProfile = false;
                }


                setTimeout(() => {
                    // this.executeSaveProfile = (/true/i).test(data);
                    // if (this.executeSaveProfile) {
                    //   console.log('TRIGGERING SAVE OF PROFILE');
                    //   this.updateProfile(false);
                    // } else {
                    //   this.executeSaveProfile = false;
                    // }
    
                }, 2000);

            });      
    }


    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.checkScreenSize();
    }

    public hideModel() {
        // this.closeModal.nativeElement.click();      
    }    

    ngAfterViewInit() {
        setTimeout(() => {
            this.dataService.setData('true');
            this.dataService.setName(this.profile.first_name + ' ' + this.profile.last_name);
            this.dataService.setVanity(this.vanity_name);
            this.dataService.setSubscriptionPlan(this.subcriptionPlan);
            this.dataService.setClient(this.client);
            this.dataService.setAppUser(this.appUser);
            this.dataService.setUserType(this.userType);
          }, 1000);
        //   this.router.navigateByUrl('/resumedetails', { skipLocationChange: true }).then(() => {
        //     this.router.navigate(['/resume-preview']);
        // })

    }

    ngOnInit(): void {
        console.log('>>>> message = ', this.message);
        console.log('vanity_name_parent = ', this.vanity_name_parent);
        console.log('title_parent = ', this.title_parent);
        console.log('recruiter_email_parent = ', this.recruiter_email_parent);


        if (this.vanity_name_parent) {
            this.vanity_name = this.vanity_name_parent;
        }
        if (this.title_parent) {
            this.title = this.title_parent;
        }
        if (this.recruiter_email_parent) {
            this.recruiter_email = this.recruiter_email_parent;
        }

        if (!XcvUtilsService.isNotNullOrEmpty(this.recruiter_email)) {
            // try to get it from the app user
            let appUser: any = this.localStorageService.getData('appUser');
            if (appUser && appUser.user_type === Constants.RECRUITER) {
                this.recruiter_email = appUser.email;
            }
        }

        this.checkScreenSize();        
        this.initExperienceFormGroup();
        this.initPublicationFormGroup();
        this.initCourseFormGroup();
        this.initEducationFormGroup();
        this.initProjectFormGroup();

        this.contactsTypeList = [
            {
                id: null,
                name: '--Select--' 
            },
            {
                id: 'email',
                name: 'Email' 
            },
            {
                id: 'twitter',
                name: 'Twitter' 
            },
            {
                id: 'phone',
                name: 'Phone' 
            }
        ];


        this.resumeListng = [
            {
                id: 'Default Resume',
                name: 'Default Resume' 
            },
            {
                id: 'JD 1',
                name: 'JD 1' 
            },
            {
                id: 'JD 2',
                name: 'JD 2' 
            },
        ];
        
        // this.postResumeDetails();
        this.bio = this.resumeDetails.Bio;
        this.contact = this.resumeDetails.Contact;
        this.resetFormGroups();
        // this.prepopulatefields();

        if (this.message === 'HAS_PARENT') {
            console.log('>>>>>>>>  WENT TO HAS PARENT');
            if (this.vanity_name) {
                this.execHasVanity();
            } else {
                this.execNoVanity();
            }
        } else {
            this.subscribeToQueryParams();
        }
    }

    private execNoVanity() {
        // no vanity name, but there has to be an appUser. Get the appUser
        let au: any = this.localStorageService.getData('appUser');
        if (XcvUtilsService.isNotNullOrEmpty(au)) {
            this.userType = au.user_type;
            this.appUser = au;

            if (this.userType === Constants.CLIENT) {
                // get the vanity name
                if (this.appUser && this.appUser.vanity_name) {
                    this.vanity_name = this.appUser.vanity_name;
                }
                
                this.getSubscriptionPlan();

                this.getClientThenSaveOnStorage();

                // now get the resume
                this.getRDetails(this.vanity_name);
            } else if (this.userType === Constants.RECRUITER) {
                if (this.appUser.email) {
                    this.recruiter_email = this.appUser.email;
                }

                // try to get the vanity name thru currrentResume or userVanity
                // if both of them are not existing, route to login
                let currrentResume = this.localStorageService.getData('currrentResume');
                if (currrentResume && currrentResume.vanity_name) {
                    this.vanity_name = currrentResume.vanity_name;
                    if (currrentResume.id === null) {
                        this.title = 'default';                         
                    } else {
                        this.title = currrentResume.nme;                         
                    }
                }

                if (!XcvUtilsService.isNotNullOrEmpty(this.vanity_name)) {
                    let userVanity = this.localStorageService.getData('userVanity');
                    if (userVanity && userVanity.vanity_name) {
                        this.vanity_name = userVanity.vanity_name;
                    }
                }

                // if still no vanity name, reroute to login
                if (!XcvUtilsService.isNotNullOrEmpty(this.vanity_name)) {
                    this.localStorageService.removeData('appUser');
                    setTimeout(() => {
                        this.dataService.setData('false');
                        this.redirectTo('login');
                    }, 1000);
                } else {
                    // now get the resume
                    this.getRDetails(this.vanity_name);
                }
            }

        } else {
            // re route to login.
            setTimeout(() => {
                this.dataService.setData('false');
                this.redirectTo('login');
            }, 1000);
        }

    }

    private execHasVanity() {
        // save to local stoage
        const data = { vanity_name: this.vanity_name};
        this.localStorageService.saveData('userVanity', data);

        // before creating, check if the appUser is existing on the local storage
        // get it and get the 'user_type'
        let au: any = this.localStorageService.getData('appUser');
        if (XcvUtilsService.isNotNullOrEmpty(au)) {
            this.userType = au.user_type;
            this.appUser = au;
        } else {
            // create the appUser and also store on storage
            let appUser: AppUser = {};
            appUser.vanity_name = this.vanity_name;
            this.localStorageService.saveData('appUser', appUser);
            this.appUser = appUser;
        }

        this.getSubscriptionPlan();
        this.getClientThenSaveOnStorage();

        // now get the resume
        this.getRDetails(this.vanity_name);
    }

    private subscribeToQueryParams() {
        console.log('>>> url = ', this.router.url);

        this.loading = true;
        this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
          if (queryParams.hasOwnProperty('title') && queryParams.title !== '') {
            this.title = queryParams.title;
          }

          if (queryParams.hasOwnProperty('recruiter_email') && queryParams.recruiter_email !== '') {
            this.recruiter_email = queryParams.recruiter_email;
          }

          if (queryParams.hasOwnProperty('startprofile') && queryParams.recruiter_email !== '') {
            this.recruiter_email = queryParams.recruiter_email;
          }

          if (queryParams.hasOwnProperty('vanity_name') && queryParams.vanity_name !== '') {
            this.vanity_name = queryParams.vanity_name;
            
            this.execHasVanity();
          } else {
            this.execNoVanity();
          }
        });
      }

      public getRDetails(id?: any) {
        // check if theres a "currentResume" in the storage, if there is,
        // check and use the title
        if (!XcvUtilsService.isNotNullOrEmpty(this.title)) {
            let au: any = this.localStorageService.getData('currentResume');
            if (au) {
                if (XcvUtilsService.isNotNullOrEmpty(au.id)) {
                    this.title = au.name;
                } else {
                    this.title = '';
                }
            }
        }

        if (this.userType === Constants.RECRUITER) {
            console.log('>>>>> RECRUITER!!!');
            let p = new AytHttpParams();
            p.set('vanity_name', id);


            if (this.title) {
                p.set('title', this.title);


                if (this.recruiter_email) {
                    p.set('recruiter_email', this.recruiter_email);
                } else {
                    // get it from the appuser
                    if (this.appUser && this.appUser.email) {
                        p.set('recruiter_email', this.appUser.email);
                    }
                }

                this.loading = true;
                this.getIndividualResume(p);
            } else {

                if (this.recruiter_email) {
                    p.set('recruiter_email', this.recruiter_email);
                } else {
                    // get it from the appuser
                    if (this.appUser && this.appUser.email) {
                        p.set('recruiter_email', this.appUser.email);
                    }
                }
                
                this.loading = true;

                this.subscription = this.userservice.getResumeDetails(p)
                .subscribe({
                  next: (response: Profile) => {
                    this.loading = false;
                    if (response) {
                        //init 
                        this.doPostretrieve(response);
                    }
                  },
                  error: (_error: any) => {
                    this.loading = false;
                  }
                });
            }
        } else {
            let p = new AytHttpParams();
            if (id) {
              p.set('vanity_name', id);
            } else {
              p.set('vanity_name', 'hassaan-ahmad-21b571222');
            }
            if (this.title) {
                // specific (JD)
                p.set('title', this.title);
                this.loading = true;
                this.getIndividualResume(p);
            } else {
                // normal
                this.loading = true;
                this.subscription = this.userservice.getResumeDetails(p)
                .subscribe({
                  next: (response: Profile) => {
                    this.loading = false;
                    if (response) {
                        //init 
                        this.doPostretrieve(response);
                    }
                  },
                  error: (_error: any) => {
                    this.loading = false;
                  }
                });
    
            }
        }
      }

      public displayExperienceDurationDateRange(exp: any) {
        let disp = '';
        if (exp && exp.starts_at) {
          disp = this.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year);
        }
        if (exp && exp.ends_at) {
          disp = disp + ' - ' + this.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year);
        }
    
        return disp;
      }

      public displayPublicationDate(pub: any) {
        let disp = '';
        if (pub && pub.published_on) {
          disp = this.convertDateFormat(pub.published_on.day, pub.published_on.month, pub.published_on.year);
        }
        return disp;
      }

      public displayprojectDuration(proj: any) {
        let disp = '';
        if (proj && proj.starts_at) {
          disp = XcvUtilsService.convertDateFormat(proj.starts_at.day, proj.starts_at.month, proj.starts_at.year, this.datePipe);
        }
        if (proj && proj.ends_at) {
          disp = disp + ' - ' + XcvUtilsService.convertDateFormat(proj.ends_at.day, proj.ends_at.month, proj.ends_at.year, this.datePipe);
        }
  
        return disp;
      }
      public convertDateFormat(day: number, month: number, year: number): any {
        const date = new Date(year, month - 1, day);
        return this.datePipe.transform(date, 'MMM d, y'); //    
      }
    
      public convertRealDateFormat(day: number, month: number, year: number): any {
        const date = new Date(year, month - 1, day);
        return date;
      }
    

      private isObject(object: any) {
        return object != null && typeof object === 'object';
      }
    
      private removeEmptyStringsFromArray(array: string[]): string[] {
        return array.filter((str) => str.trim() !== '');
      }
    
      private addSpaceBetweenCommas(inputString: string): string {
        return inputString
          .split(',')
          .map((value) => value.trim())
          .join(', ');
      }
    
      private trimSpacesBetweenCommas(inputString: string): string {
        return inputString
          .split(',')
          .map((value) => value.trim())
          .join(',');
      }  
    
      private checkChanged(profile: Profile) {
        profile.hasChanged = false;
        console.log('>>> check changed executed');
    
        // about
        if (profile.aboutOrig !== profile.about) {
          profile.aboutChanged = true;
          profile.hasChanged = true;
        } else {
          profile.aboutChanged = false;
        }
    
        // contact phone
        let newC = new ProfileContact();
        newC.email = [];
        newC.phone = [];
        newC.phone.push(this.phoneSection);


        profile.contact?.phone?.push(this.phoneSection);
        console.log('this.phoneSection = ',this.phoneSection);

        console.log('profile.contactOrig = ', profile.contactOrig);
        console.log('newC = ', newC);

        if (!this.deepEqual(profile.contactOrig, newC, 'phone')) {

        // if (!this.deepEqual(profile.contactOrig?.phone, profile.contact?.phone, 'phone')) {
          profile.contactPhoneChanged = true;
          profile.hasChanged = true;
        } else {
            profile.contactPhoneChanged = false;
        }
    
        // country
        if (profile.countryOrig !== profile.country) {
          profile.countryChanged = true;
          profile.hasChanged = true;
        } else {
          profile.countryChanged = false;
        }
    
        // education array
        if (!this.deepEqual(profile.educationOrig, profile.education)) {
          profile.educationChanged = true;
          profile.hasChanged = true;
        } else {
          profile.educationChanged = false;
        }
    
        // experience array
        // if (!this.deepEqual(profile.experienceOrig, profile.experience)) {
        //   profile.experienceChanged = true;
        //   profile.hasChanged = true;
        // } else {
        //   profile.experienceChanged = false;
        // }
    
        // first_name
        if (profile.first_nameOrig !== profile.first_name) {
          profile.first_nameChanged = true;
          profile.hasChanged = true;
        } else {
          profile.first_nameChanged = false;
        }
    
        // languages array
        if (profile.languagesDisplay) {
          let languageValue: string = this.trimSpacesBetweenCommas(profile.languagesDisplay);
          this.profile.languages = languageValue.split(',');
          this.profile.languages = this.removeEmptyStringsFromArray(this.profile.languages);
          if (this.profile.languages) {
            this.profile.languages.forEach((s: string) => {
              s.trim();
            });
          }
          if (!this.deepEqual(profile.languagesOrig, profile.languages)) {
            profile.languagesChanged = true;
            profile.hasChanged = true;
          } else {
            profile.languagesChanged = false;
          }
        }
    
        // last_name
        if (profile.last_nameOrig !== profile.last_name) {
          profile.last_nameChanged = true;
          profile.hasChanged = true;
        } else {
          profile.last_nameChanged = false;
        }
    
        // lcoation
        if (profile.lcoationOrig !== profile.lcoation) {
          profile.locationChanged = true;
          profile.hasChanged = true;
        } else {
          profile.locationChanged = false;
        }
    
        // skills array
        if (profile.skillsDisplay) {
          let skillValue: string = this.trimSpacesBetweenCommas(profile.skillsDisplay);
          this.profile.skills = skillValue.split(',');
          this.profile.skills = this.removeEmptyStringsFromArray(this.profile.skills);
          if (this.profile.skills) {
            this.profile.skills.forEach((s: string) => {
              s.trim();
            });
          }
          if (!this.deepEqual(profile.skillsOrig, profile.skills)) {
            profile.skillsChanged = true;
            profile.hasChanged = true;
          } else {
            profile.skillsChanged = false;
          }
        }
    
        // title
        if (profile.titleOrig !== profile.title) {
          profile.titleChanged = true;
          profile.hasChanged = true;
        } else {
          profile.titleChanged = false;
        }
    
      }
    
      private loadOrigDetailValues(profile: Profile) {
        profile.aboutOrig = profile.about;
        profile.contactOrig = profile.contact;
        profile.countryOrig = profile.country;
        profile.educationOrig = profile.education;
        profile.experienceOrig = profile.experience;
        profile.first_nameOrig = profile.first_name;
        profile.languagesOrig = profile.languages;
        profile.last_nameOrig = profile.last_name;
        profile.lcoationOrig = profile.lcoation;
        profile.skillsOrig = profile.skills;
        profile.titleOrig = profile.title;
      }
    
      private deepEqual(object1: any, object2: any, field?: string): boolean {


        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
    
        if (field) {
          console.log('field = ', field);
          console.log('keys1 = ', keys1);
          console.log('keys2 = ', keys2);
        }
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (const key of keys1) {
            for (const keyB of keys2) {

                if (key === keyB) {


                    const val1 = object1[key];
                    const val2 = object2[keyB];
          

                    if (field) {
                        console.log('val1 = ', val1);
                        console.log('val2 = ', val2);
                    }

                    const areObjects = this.isObject(val1) && this.isObject(val2);
                    if (
                      areObjects && !this.deepEqual(val1, val2) ||
                      !areObjects && val1 !== val2
                    ) {
                      return false;
                    }
          
                }

            }



    
    
      
    
        }
      
        return true;
      }
        



    public prepopulatefields(): void {
        this.resetFormGroups();
        this.expinfo = this.resumeDetails.Experience.Publications;
        this.skillsinfo = this.profile.skills;
        this.langinfo = this.profile.languages;
        this.certificateinfo = this.profile.certifications;

        this.expinfo.forEach((exp: any) => {
            this.addExp(exp)
        })
        
        this.langinfo.forEach((lang: any) => {
            this.addLang(lang)
        })
        this.skillsinfo.forEach((skills: any) => {
            this.addSkills(skills)
        })

        if (this.certificateinfo) {
            this.certificateinfo.forEach((certificates:any) => {
                this.addCertificate(certificates)
            })
        }

        if (this.profile.socials) {
            this.contact.facebook = this.profile.socials.facebook;
            this.contact.twitter = this.profile.socials.twitter;
        }

        if (this.profile.contact) {
            this.contact.email = this.profile.contact.email;
            this.contact.phone = this.profile.contact.phone;
            if (this.contact.email) {
                this.emails = this.contact.email;
                this.selectedEmail = this.emails[0];
            }

            if (this.contact.phone) {
                this.phones = this.contact.phone;
                this.selectedPhone = this.phones[0];
            }

        }

        if (this.contact) {
            if (this.contact.facebook === '' || this.contact.facebook === 'None' ) {
                this.contact.facebook = '(Not Indicated)';
            }
            if (this.contact.twitter === '' || this.contact.twitter === 'None' ) {
                this.contact.twitter = '(Not Indicated)';
            }
        }

        // only happens once on initial load (about)
        if (this.initialLoadAboutForCurrent) {
            // do a save of whatever is the context of about on the about cache
            this.addToAboutCache(this.profile.about);
            this.initialLoadAboutForCurrent = false;
        }

        // only happens once on initial load (experience)
        if (this.initialLoadExperienceForCurrent) {
            // do a save of whatever is the context of each experince on its own cache
            if (this.profile.experience) {
                this.profile.experience.forEach((ex: ProfileExperience) => {
                    this.addToExpCache(ex.description, ex);
                });
            }
            this.initialLoadExperienceForCurrent = false;
        }
        
    }

 
    /////////////////////////// experience array ////////////////////////////////////////////
    get experience(): FormArray {
        return <FormArray>this.resumeForm.get('experienceArray');
    }

    addExp(experience: any) {
        this.experience.push(this.createExpGroup(experience));
    }

    createExpGroup(experience: any): FormGroup {
        return this.fb.group({
            experience: [experience.name, [Validators.required]],
        })
    }

    addNewExperience() {
        this.addExp({ experience: null });
    }



    removeGroup(i: number) {
        this.experience.removeAt(i);
    }
    /////////////////////////////// certification array ////////////////////////////////////////
    get certifications(): FormArray {
        return <FormArray>this.resumeForm.get('certificateArray');
    }
    addCertificate(certifications: any) {
        this.certifications.push(this.createCertificateGroup(certifications));
    }
    createCertificateGroup(certifications: any): FormGroup {
        return this.fb.group({
            certifications: [certifications, [Validators.required]],
        })
    }

    addCertification(){
        this.addCertificate({ certifications: null });
    }
    public removecertificate(index:number)
    {
        this.certifications.removeAt(index);
    }

    addNewCert() {
        const arr = this.resumeForm.get('certificateArray') as FormArray
        arr.push(this.createnewCertGroup())
    }

    createnewCertGroup(): FormGroup {
        return new FormGroup({
          'certifications': new FormControl('', Validators.required)
        })
    } 




    /////////////////////////////// languages array //////////////////////////////////////////////////
    addLang(languages: any) {
        this.languages.push(this.createLangGroup(languages));
    }

    get languages(): FormArray {
        return <FormArray>this.resumeForm.get('langArray');
    }

    createLangGroup(languages: any): FormGroup {
        // return this.fb.group({
        //     languages: [languages.name, [Validators.required]],
        // })

        return this.fb.group({
            languages: [languages, [Validators.required]],
        })

    }

    addNewLanguage() {
        // this.addLang({ languages: null });

        const sk = this.resumeForm.get('langArray') as FormArray
        sk.push(this.createnewLangGroup())

    }

    createnewLangGroup(): FormGroup {
        return new FormGroup({
          'languages': new FormControl('', Validators.required)
        })
    } 


    public removelanguage(index:number)
    {
        this.languages.removeAt(index);
    }
    //////////////////////////  skills array ////////////////////////////////////////
    public addSkills(skills: any) {
        this.skills.push(this.createskillGroup(skills));
    }
    get skills(): FormArray {
        return <FormArray>this.resumeForm.get('skillsArray');
    }

    createskillGroup(skills: any): FormGroup {
        return this.fb.group({
            skills: [skills, [Validators.required]],
        })
    }

    addNewskill() {
        // this.addSkills({ skills: null });
        const sk = this.resumeForm.get('skillsArray') as FormArray
        sk.push(this.createnewSkillGroup())
    }

    createnewSkillGroup(): FormGroup {
        return new FormGroup({
          'skills': new FormControl('', Validators.required)
        })
    } 



    public removeskill(index: number) {
        this.skills.removeAt(index);
    }

    public postResumeDetails() {

        const url = 'https://xcv.ngrok.dev/client/profile/';
        let queryParams = new HttpParams();
        queryParams = queryParams.append('public_id', this.public_key);
        this.http.get<any>(url).subscribe(
            {
                next: (_response: any) => {
                    //   console.log(response)
                    // this.resumeDetails = response;
                    // this.bio = response.Bio;
                    // this.contact = response.Contact;

                },
                error: (_error: any) => { }
            }

        )
    }

    editResume(section: string) {
        if (section === "name") {
            this.isEditName = !this.isEditName;
        }
        if (section === "title") {
            this.isEditTitle = !this.isEditTitle;
        }
        if (section === "experience") {
            this.isEditexperience = !this.isEditexperience;
        }
        if (section === "skills") {
            this.isEditskills = !this.isEditskills;
        }
        if (section === "langs") {
            this.isEditlanguages = !this.isEditlanguages;
        }
        if (section === "courses") {
            this.isEditcourses = !this.isEditcourses;
        }
        if (section === "publications") {
            this.isEditpublications = !this.isEditpublications;
        }
        
        if (section === "phone") {
            this.isEditPhone = !this.isEditPhone;
        }
        if (section === 'about') {

            console.log('>>>> isEditAbout =  ', this.isEditAbout);

            if (!this.isEditAbout) {
                this.isEditAbout = true;
            } else {
                // return previous value
                // this.profile.about = this.profileOrig.about;
                this.isEditAbout = false;

            }

            // if (this.isEditAbout) {
            // }


            // this.isEditAbout = !this.isEditAbout;
        }
    }

    public updateProfile(isGenerated: boolean, section?: string) {
        console.log('1. [][][][][][][][][][][][][][][][] updateProfile start');
        // before updating, check which field changed
        // create payload
        let payload: any = {};
        payload.vanity_name = this.profile.vanity_name;
        payload.payload = {};
        if (this.jd_id === null || this.jd_id === 'null' || this.jd_id === '') {
            payload.payload.id ='None';
        } else {
            payload.payload.id = String(this.jd_id);
        }

        // check if login is recruiter, if it is then add the recuiter email
        if (this.userType === Constants.RECRUITER) {
            payload.recruiter_email = this.recruiter_email;
        }

        let sec_att: any = {};
        let subsec_att: any = {};

        console.log('>>>> passed section = ', section);

        if (section) {
            switch ( section ) {
                case 'skills':
                    if (isGenerated) {

                        while (this.skills.length !== 0) {
                            this.skills.removeAt(0);
                        }

                        this.gptrevisedData.forEach((s: string) => {
                            this.addSkills(s);
                        });

                        this.profile.skills = this.gptrevisedData;
                        this.showModal = false;

                        this.profile.skillsChanged = true;
                        this.profile.hasChanged = true;

                    } else {
                        let sa: string[] = [];
                        console.log('this.skills.getRawValue = ', this.skills.getRawValue());
                        if (this.skills.getRawValue()) {
                            this.skills.getRawValue().forEach((s: any) => {
                                sa.push(s.skills);
                            });

                            this.profile.skillsChanged = true;
                            this.profile.hasChanged = true;

                            this.isEditskills = false;                        


                            
                            // subsec_att.skills = sa;
                            // this.executeUpdate(payload, sec_att, subsec_att);

                        } else {
                            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `No skills`, life: 5000});
                        }

                    }
                    break;
                case 'languages':
                    if (isGenerated) {

                        while (this.languages.length !== 0) {
                            this.languages.removeAt(0);
                        }

                        this.gptrevisedData.forEach((s: string) => {
                            this.addLang(s);
                        });

                        this.profile.languages = this.gptrevisedData;
                        this.showModal = false;

                        this.profile.languagesChanged = true;
                        this.profile.hasChanged = true;

                    } else {
                        let la: string[] = [];
                        console.log('this.languages.getRawValue = ', this.languages.getRawValue());
                        if (this.languages.getRawValue()) {
                            this.languages.getRawValue().forEach((s: any) => {
                                la.push(s.languages);
                            });

                            this.profile.languagesChanged = true;
                            this.profile.hasChanged = true;

                            this.isEditlanguages = false;                        

                            // subsec_att.languages = la;
                            // this.executeUpdate(payload, sec_att, subsec_att);
                        } else {
                            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `No languages`, life: 5000});
                        }
                    }
                    break;

                case 'certifications':
                    let ce: string[] = [];
                    console.log('this.certifications.getRawValue = ', this.certifications.getRawValue());
                    if (this.certifications.getRawValue()) {
                        this.certifications.getRawValue().forEach((s: any) => {
                            ce.push(s.certifications);
                        });

                        this.profile.certificationsChanged = true;
                        this.profile.hasChanged = true;

                        this.isEditcourses = false;                        
                        // subsec_att.certifications = ce;
                        // this.executeUpdate(payload, sec_att, subsec_att);

                    } else {
                        this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `No certifications`, life: 5000});
                    }
                break;
                case 'name':
                    this.profile.first_nameChanged = true ;
                    this.profile.last_nameChanged = true;
                    this.profile.hasChanged = true;
                    this.isEditName = false;
                break;
                case 'title':
                    this.profile.titleChanged = true ;
                    this.profile.hasChanged = true;
                    this.isEditTitle = false;
                break;
                case 'about':
                    if (isGenerated) {
                        // sec_att.about = this.gptrevisedData;
                        // this.executeUpdate(payload, sec_att, subsec_att);
                        this.profile.about = this.gptrevisedData;
                        this.showModal = false;

                        this.profile.aboutChanged = true;
                        this.profile.hasChanged = true;
                    } else {
                        if (this.profile.about) {
                            // sec_att.about = this.profile.about;
                            // this.executeUpdate(payload, sec_att, subsec_att);

                            this.profile.aboutChanged = true;
                            this.profile.hasChanged = true;
                            this.isEditAbout = false;

                            // iterate thru the abouts cache if its unique. if its unique, then add it
                            let isUnique = true;
                            if (XcvUtilsService.isNotEmpty(this.profile.aboutDataCurrentArray)) {
                                for (let i = 0; i < this.profile.aboutDataCurrentArray.length; i ++) {
                                    let abtCache: string =  this.profile.aboutDataCurrentArray[i];
                                    if (this.profile.about === abtCache) {
                                        isUnique = false;
                                        break;
                                    }
                                }
                            }

                            if (XcvUtilsService.isNotEmpty(this.profile.aboutDataUndoArray)) {
                                for (let i = 0; i < this.profile.aboutDataUndoArray.length; i ++) {
                                    let abtCache: string =  this.profile.aboutDataUndoArray[i];
                                    if (this.profile.about === abtCache) {
                                        isUnique = false;
                                        break;
                                    }
                                }
                            }

                            if (XcvUtilsService.isNotEmpty(this.profile.aboutDataRedoArray)) {
                                for (let i = 0; i < this.profile.aboutDataRedoArray.length; i ++) {
                                    let abtCache: string =  this.profile.aboutDataRedoArray[i];
                                    if (this.profile.about === abtCache) {
                                        isUnique = false;
                                        break;
                                    }
                                }
                            }

                            if (isUnique) {
                                // add it
                                this.addToAboutCache(this.profile.about);
                            }
                        } else {
                            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `No about`, life: 5000});
                        }
                    }
                    break;

                    case 'experience':
                        if (this.selectedExpId > 0) {
                            // find it from the list of experiences of the profile using the id
                            // then replace the old description with the generated description
                            if (this.profile.experience) {
                                console.log('>>>>>>> it went here!!!!!!!!');

                                for (let i = 0; i < this.profile.experience.length; i++) {
                                    let exp: ProfileExperience = this.profile.experience[i];
                                    if (exp.expId === this.selectedExpId) {
                                        exp.description = this.gptrevisedData;

                                        this.profile.experienceChanged = true;
                                        this.profile.hasChanged = true;


                                        break;
                                    }

                                }
                                this.showModal = false;


                            }
                        }
                    break;
    

                default: 
                // 
                break;
            }
            console.log('1A. [][][][][][][][][][][][][][][][] this.profile.hasChanged under section = ', this.profile.hasChanged);

            // if (this.profile.hasChanged) {
            //     // store it on local storage to persist
            //     let profileChanged: any = {};
            //     profileChanged.hasChanged = true;
            //     this.localStorageService.saveData('profileChanged', profileChanged);
            // }

        } else {
            // let profileChanged: any = this.localStorageService.getData('profileChanged');
            // if (profileChanged && profileChanged.hasChanged) {
            //     this.profile.hasChanged = true;
            //     this.localStorageService.removeData('profileChanged');
            // }


            // this.checkChanged(this.profile);
    
            if (this.profile.hasChanged) {
                console.log('1B. [][][][][][][][][][][][][][][][] this.profile.hasChanged NOT SECTION = ', this.profile.hasChanged);

                // for about section
                if(this.profile.aboutChanged) {
                    console.log('>>> went to about');
                    sec_att.about = this.profile.about;
                }

                if(this.profile.first_nameChanged)
                {
                    sec_att.first_name;
                    sec_att.last_name;
                }

                if(this.profile.titleChanged)
                {
                    sec_att.title = this.profile.title;
                }

                // for experience section
                if (this.profile.experienceChanged) {
                    subsec_att.experience = this.profile.experience;
                }
    
                // for courses/certifications section
                if (this.profile.certificationsChanged) {
                    console.log('>>> went to courses/certifications');
                    let ce: string[] = [];
                    this.certifications.getRawValue().forEach((s: any) => {
                        ce.push(s.certifications);
                    });
                    subsec_att.certifications = ce;
                }

                // for skills section
                if (this.profile.skillsChanged) {
                    console.log('>>> went to skills');
                    let sa: string[] = [];
                    if (this.skills.getRawValue()) {
                        this.skills.getRawValue().forEach((s: any) => {
                            sa.push(s.skills);
                        });
                        subsec_att.skills = sa;
                    }
                }

                // for education section
                if (this.profile.educationChanged) {
                    console.log('>>> went to education');
                    subsec_att.education = this.profile.education;
                }

                // for accomplishment section (mainly the section toggles)
                // if (this.profile.accompishmentsChanged) {
                //     console.log('>>> went to section toggles of accomplishments');
                //     subsec_att.accomplishments = this.profile.accomplishments;
                // }

                // for visibility section
                if (this.profile.visibilityChanged) {
                    console.log('>>> went to visibility');
                    subsec_att.visibility = this.profile.visibility;
                }

                // for project section
                if (this.profile.projectChanged) {
                    console.log('>>> went to project');
                    subsec_att.accomplishments = this.profile.accomplishments;
                }


                // for experience section
                if (this.profile.experienceChanged) {
                    console.log('>>> went to experience');
                    subsec_att.experience = this.profile.experience;
                }

                // for publications section
                if (this.profile.accomplishments && this.profile.accomplishments.publicationsChanged) {
                    console.log('>>> went to publictions');
                    subsec_att.accomplishments = this.profile.accomplishments;
                }

                // for courses section
                if (this.profile.accomplishments && this.profile.accomplishments.coursesChanged) {
                    console.log('>>> went to courses');
                    subsec_att.accomplishments = this.profile.accomplishments;
                }

                // for contact(email) / phone section
                if (this.profile.contactChanged || this.profile.contactPhoneChanged) {
                    subsec_att.contact = {};
                    if (this.profile.contact) {
                        this.profile.contact.email = this.contact.email;
                        this.profile.contact.phone = this.contact.phone;
                        subsec_att.contact.email = this.profile.contact?.email;
                        subsec_att.contact.phone = this.profile.contact?.phone;
                    }
                }
              
                if (this.profile.contactTwitterChanged) {
                    if (this.profile.socials) {
                        subsec_att.socials = {};
                        subsec_att.socials.twitter = this.contact.twitter;
                        subsec_att.socials.facebook = '';
                    }
                }

                 // for name section
                 if (this.profile.first_nameChanged) {
                    sec_att.first_name = this.profile.first_name;
                    sec_att.last_name = this.profile.last_name;
                }

                // for title section
                if (this.profile.titleChanged) {
                    sec_att.title = this.profile.title;
                }
            
                // for country section
                if (this.profile.countryChanged) {
                    subsec_att.country = this.profile.country;
                }
        
                // for languages section
                if (this.profile.languagesChanged) {
                    let sa: string[] = [];
                    if (this.languages.getRawValue()) {
                        this.languages.getRawValue().forEach((s: any) => {
                            sa.push(s.languages);
                        });
                        subsec_att.languages = sa;
                    }
                // subsec_att.languages = this.profile.languages;
                }

                this.executeUpdate(payload, sec_att, subsec_att);
            } else {
                console.log('1C. [][][][][][][][][][][][][][][][] this.profile.hasChanged = ', this.profile.hasChanged);

              this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Nothing was modified`, life: 5000});

              // for the "Save" button on the parent to stop spinning
              this.saveExecutedEvent.emit('true');
            }
    
        }

    }
    
    public executeUpdate(payload: any, sec_att: any, subsec_att: any) {
        payload.payload.section_attr = sec_att;
        payload.payload.subsection_attr = subsec_att;

        console.log('>>> the payload = ', payload);
        this.confirmUpdateProfile(payload);
    }

    public confirmUpdateProfile(payload: any) {
        console.log('2. [][][][][][][][][][][][][][][][] before updating....');

        this.updating = true;

        this.subscription = this.userservice.updateResume(payload)
        .subscribe({
          next: response => {
            console.log('3. [][][][][][][][][][][][][][][][] after updating....');

            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Resume successfully updated`, life: 5000});


            if (this.message === 'HAS_PARENT') {
                this.sendMessage('Resume successfully updated');
            }

            // triggering the reloading of the template so latest changes can also be seen there
            this.dataService.setReloadTemplate('true');

            // this will trigger parent that save is finished and it will stop "processing" on the parent "Save" button
            this.saveExecutedEvent.emit('true');

            this.updating = false;
            if (response) {
              this.closeInputFields();
    
              // reload
              console.log('loading... jd_id = ', this.jd_id);
              console.log('loading... jd_title = ', this.jd_title);

              if (this.jd_id === 'None') {
                this.getRDetails(this.vanity_name)
              } else {

                let p = new AytHttpParams();
                p.set('vanity_name', this.vanity_name);
            
                p.set('title', this.title);
                if (this.userType === Constants.RECRUITER) {
                    p.set('recruiter_email', this.recruiter_email);
                }
                
                this.loading = true;
                this.getIndividualResume(p);
              }
            }
          },
          error: error => {
            // this will trigger parent that save is finished and it will stop "processing" on the parent "Save" button
            this.saveExecutedEvent.emit('true');

            XcvUtilsService.handleError(error, this.messageService);
            this.updating = false;
          }
        });
    
    }

    private closeInputFields(): void {
        this.isEditCountry = false;
        this.isEditlanguage = false;
        this.isSkills = false;
        this.isEditTitle = false;
        this.isEditPhone = false;
        this.isEditskills = false;
        this.isEditlanguages = false;
        this.isEditcourses = false;
        this.isEditAbout = false;
        this.gptModal = false;
        this.showModal = false;
    }
    
    public resetFormGroups(): void {
        this.resumeForm = this.fb.group({
            experienceArray: this.fb.array([]),
            skillsArray: this.fb.array([]),
            langArray: this.fb.array([]),
            certificateArray:this.fb.array([]),
            publicationsArray:this.fb.array([])
        });
    }

    public showRevisedText(section: any, id?: number): void {
        this.showModal = true;
        let isValid = true;

        let payload: any = {};
        payload.vanity_name = this.vanity_name;

        if (this.userType === Constants.RECRUITER) {
            payload.recruiter_email = this.recruiter_email;
        }

        this.selectedSection = section;
        switch ( section ) {
            case 'about':
                payload.about = this.profile.about;
            break;
            case 'skills':
                let sa: any[] = [];
                this.skills.getRawValue().forEach((s: any) => {
                    sa.push(s.skills);
                });
                payload.skills = sa;

                console.log('payload.skills = ', payload.skills);
                if (payload.skills && payload.skills.length === 0) {
                    this.showModal = false;
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `You have no skills to revise`, life: 5000});
                    isValid = false;
                }

            break;
            case 'experience':

            console.log('experience all = ', this.profile.experience);
            console.log('id = ', id);

            if (id) {
                this.selectedExpId = id;
            }

            if (this.profile.experience) {
                for (let i = 0; i < this.profile.experience.length; i++) {
                    let expItem: ProfileExperience = this.profile.experience[i];

                    if (id === expItem.expId) {

                        // assign to 'selectedExperience', will be used later
                        this.selectedExperience = expItem;

                        payload.experience = expItem.description;
                        if (expItem.title) {
                            this.selectedTitle = expItem.title;
                        }
                        if (expItem.company) {
                            this.selectedCompny = expItem.company;
                        }
                        // TODO not renoving this entirely, might need it, just commenting it out
                        // payload.jobRole = expItem.title;
                        // payload.company = expItem.company;
                        break;
                    }

                }

            }
    
            break;
            default: 
            // 
            break;
        }


        if (isValid) {
            this.gptGenerating = true;
            this.gptModal = false;
    
            this.subscription = this.userservice.getnerateChatGPT(payload)
            .subscribe({
              next: response => {
                this.updating = false;
                if (response) {

                    if (response.hasOwnProperty('exception')) {
                        // this is an error
                        this.hideModel();                    
                        this.gptGenerating = false;
                        this.gptModal = false;
                        // this.showModal = false;
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: response.exception, life: 5000});
                    } else {

                    // once GPT is invoked, tokens are consumed, you need to get the latest token count
                    this.getClientThenSaveOnStorage();

    
                    this.gptGenerating = false;
                    this.gptModal = true;
                    this.showModal = true;
            
                    if (section == 'profile') {
                      this.gptrevisedData = this.revised.profile;
                      this.payloadV = 'profile'
                    }
                    else if (section == 'rprofile') {
                      this.gptrevisedData = this.revised.revisedprofile;
                      this.payloadV = 'revisedprofile'
                    }
                    else if (section == 'about') {
                      this.gptrevisedData = response.refined_about;
                      this.payloadV = 'about'

                      // add this generated on the cache
                      this.addToAboutCache(this.gptrevisedData);
                    }
                    else if (section == 'skills') {
    
                        // this.gptrevisedData = response.refined_skills;
                        console.log('esponse.refined_skills = ', response.refined_skills);
    
                        let cleanArray: string[] = []; 
                        if(Object.prototype.toString.call(response.refined_skills) === '[object Array]') {
                            response.refined_skills.forEach((s: string) => {
                                if(s) {
                                    cleanArray.push(s);
                                }
                            });
    
                            this.gptrevisedData = cleanArray;
                            console.log('cleanArray = ', cleanArray);
                        }                    
    
    
                        // {
                        //     "refined_skills": [
                        //       "",
                        //       "software engineer",
                        //       "Java programmer",
                        //       "software architect",
                        //       ""
                        //     ]
                        //   }
    
                        this.payloadV = 'skills'
                    }
                    else if (section == 'experience') {
                        this.gptrevisedData = response.refined_experience;
                        this.payloadV = 'experience';
                        this.addToExpCache(this.gptrevisedData, this.selectedExperience);
                    }
    
    
                    }
    
    
    
      
                }
              },
              error: error => {
                XcvUtilsService.handleError(error, this.messageService);
                console.log('this.userType = ', this.userType);
                // do this to update the token info, even if its error
                this.getClientThenSaveOnStorage();

                // this is an error
                this.hideModel();                    
                this.gptGenerating = false;
                this.gptModal = false;
                this.showModal = false;
            }
            });
        }

    }

    public isGPTArray(gptrevisedData: any): boolean {
        if(Object.prototype.toString.call(gptrevisedData) === '[object Array]') {
            return true;
        } else {
            return false;
        }
    }

    public getIndividualResume(p: any): void {
        this.subscription = this.userservice.getIndividualResume(p)
        .subscribe({
          next: (response: Profile) => {
            this.loading = false;
            if (response) {
                this.jd_id = response.jd;
                //init 
                this.doPostretrieve(response);
            }
          },
          error: (_error: any) => {
            this.loading = false;
          }
        });
    }

    public selectResume(obj: any): void {
        let p = new AytHttpParams();
        p.set('public_id', this.vanity_name);
        if (obj.value.id === null || obj.value.id === '' || obj.value.id === 'null') {
            p.set('title', 'default');
        } else {
            p.set('title', obj.value.name);
            this.jd_title =  obj.value.name;
        }
        this.loading = true;
        this.getIndividualResume(p);
    }

    public getResumeList(): void {
        let p = new AytHttpParams();
        p.set('vanity_name', this.vanity_name);
        this.subscription = this.userservice.getAllResumes(p)
        .subscribe({
          next: response => {
            if (response) {
                this.resumeListng = [];
                if (response) {
 
                    let def:any = {};
                    def.id = null;
                    def.name = 'Default Resume';
                    this.resumeListng.push(def);

                    response.forEach((el:any) => {
                    let obj:any = {};
                    obj.id = el.id;
                    obj.name = el.title;
                    this.resumeListng.push(obj);
                    });
                }
            }
          },
          error: error => {
            XcvUtilsService.handleError(error, this.messageService);
            this.updating = false;
          }
        });
    }

    private doPostretrieve(response: Profile) {
        this.resumeRetrievedEvent.emit('true');

        // before resetting, get the cache for about
        this.persistAboutCacheRoutine(response);

        // before resetting, get the cache for experience
        this.persistExperienceCacheRoutine(response);

        this.profile = new Profile(); 
        this.profile.contact = new ProfileContact();
        let emails: string[] = [];
        this.profile.contact.email = emails;
        let phones: string[] = [];
        this.profile.contact.phone = phones;

        this.profile = response;

        console.log('>>>>>>> this.profile = ', this.profile);

        if (this.profile && this.profile.contact && this.profile.contact.email && this.profile.contact.email.length > 0) {
            this.clientEmailEvent.emit(this.profile.contact.email[0]);
        }

        // this puts a copy the original values which will be used for comparison later
        this.loadOrigDetailValues(this.profile);

        // process the object
        // 1. make the languages to string
        if (this.profile.languages && this.profile.languages.length > 0) {
            this.profile.languagesDisplay = this.profile.languages.join(', ');
            this.profile.languagesDisplay = this.trimSpacesBetweenCommas(this.profile.languages.join(', '));
            this.profile.languagesDisplay = this.addSpaceBetweenCommas(this.profile.languagesDisplay);
        } else {
            this.profile.languagesDisplay = '';
        }

        // 2. make the skills to string
        if (this.profile.skills && this.profile.skills.length > 0) {
            this.profile.skillsDisplay = this.trimSpacesBetweenCommas(this.profile.skills.join(', '));
            this.profile.skillsDisplay = this.addSpaceBetweenCommas(this.profile.skillsDisplay);
        } else {
            this.profile.skillsDisplay = '';
        }

        if (this.profile.experience) {
            // generate id
            let n = this.getUniqueID();
            this.profile.experience.forEach((ex: ProfileExperience) => {
                // n = n + 1;
                n = this.getUniqueID();
                ex.expId = n;
                if (!ex.description || ex.description === '') {
                    ex.description = '(None)';
                }
            });

            // do the sorting here
            XcvUtilsService.sortExperience(this.profile.experience, Constants.DESC);
            this.profileExperience = this.profile.experience;
        }

        // do the publication sorting here
        if (this.profile.accomplishments && this.profile.accomplishments.publications) {
            XcvUtilsService.sortPublications(this.profile.accomplishments.publications, Constants.DESC);
        }

        // do the education sorting here
        if (this.profile.education) {
            this.profile.education = XcvUtilsService.sortEducation(this.profile.education);
        }

        // do the projects sorting here
        if (this.profile?.accomplishments?.projects) {
            this.profile.accomplishments.projects = XcvUtilsService.sortProject(this.profile.accomplishments.projects);
        }


        // do the "section visible check"
        // start courses
        if (!this.profile?.visibility?.hasOwnProperty('courses') ) {
            if (this.profile.visibility) {
                this.profile.visibility.courses = true;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
        }
        if (this.profile.visibility && 
            (
                this.profile.visibility.courses === true ||
                this.profile.visibility.courses === false
            )
        ) {
        this.coursesVisible =  this.profile.visibility.courses;
        }
        // end courses

        // start projects
        if (!this.profile?.visibility?.hasOwnProperty('projects') ) {
            if (this.profile.visibility) {
                this.profile.visibility.projects = true;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
        }
        if (this.profile.visibility && 
            (
                this.profile.visibility.projects === true ||
                this.profile.visibility.projects === false
            )
        ) {
        this.projectsVisible =  this.profile.visibility.projects;
        }
        // end projects

        // start publications
        if (!this.profile?.visibility?.hasOwnProperty('publications') ) {
            if (this.profile.visibility) {
                this.profile.visibility.publications = true;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
        }
        if (this.profile.visibility && 
            (
                this.profile.visibility.publications === true ||
                this.profile.visibility.publications === false
            )
        ) {
        this.publicationsVisible =  this.profile.visibility.publications;
        }
        // end publications

        // start certifications
        if (this.profile.visibility &&
            (
                this.profile.visibility.certifications === true ||
                this.profile.visibility.certifications === false
            )
            ) {
            this.certificationsVisible = this.profile.visibility.certifications;
        }
        // end certifications

        // start experience
        if (this.profile.visibility &&
            (
                this.profile.visibility.experience === true ||
                this.profile.visibility.experience === false
            )
            ) {
            this.experienceVisible = this.profile.visibility.experience;
        }
        // end experience

        // start skills
        if (this.profile.visibility &&
            (
                this.profile.visibility.skills === true ||
                this.profile.visibility.skills === false
            )
            ) {
            this.skillsVisible = this.profile.visibility.skills;
        }
        // end skills

        // start education
        if (this.profile.visibility &&
            (
                this.profile.visibility.education === true ||
                this.profile.visibility.education === false
            )
            ) {
            this.educationVisible = this.profile.visibility.education;
        }
        // end education

        // start languages
        if (this.profile.visibility &&
            (
                this.profile.visibility.languages === true ||
                this.profile.visibility.languages === false
            )
            ) {
            this.languagesVisible = this.profile.visibility.languages;
        }
        // end languages

               //start email
               if (!this.profile?.visibility?.hasOwnProperty('email') ) {
                if (this.profile.visibility) {
                    this.profile.visibility.email = true;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            }
            if (this.profile.visibility &&
                (
                    this.profile.visibility.email === true ||
                    this.profile.visibility.email === false
                )
                ) {
                this.emailVisible = this.profile.visibility.email;
            }
            //end email

            
               //start linkedin
               if (!this.profile?.visibility?.hasOwnProperty('linkedin') ) {
                if (this.profile.visibility) {
                    this.profile.visibility.linkedin = true;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            }
            if (this.profile.visibility &&
                (
                    this.profile.visibility.linkedin === true ||
                    this.profile.visibility.linkedin === false
                )
                ) {
                this.lnkdinVisible = this.profile.visibility.linkedin;
            }
            //end linkedin

        //start twitter
        if (!this.profile?.visibility?.hasOwnProperty('twitter') ) {
            if (this.profile.visibility) {
                this.profile.visibility.twitter = true;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
        }
        if (this.profile.visibility &&
            (
                this.profile.visibility.twitter === true ||
                this.profile.visibility.twitter === false
            )
            ) {
            this.twitterVisible = this.profile.visibility.twitter;
        }
        //end twitter

        //start contactno
        if (!this.profile?.visibility?.hasOwnProperty('contactno') ) {
            if (this.profile.visibility) {
                this.profile.visibility.contactno = true;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
        }
        if (this.profile.visibility &&
            (
                this.profile.visibility.contactno === true ||
                this.profile.visibility.contactno === false
            )
            ) {
            this.contactnoVisible = this.profile.visibility.contactno;
        }
        //end contactno

        // do generate id for education if not existing
        if (this.profile.education && this.profile.education.length > 0) {
            this.profile.education.forEach((edu: ProfileEducation) => {
                if (!edu.hasOwnProperty('eduId')) {
                    edu.eduId = this.getUniqueID();
                }
            });
        }

        // do generate id for courses if not existing
        if (this.profile.accomplishments?.courses && this.profile.accomplishments?.courses.length > 0) {
            this.profile.accomplishments.courses.forEach((cou: ProfileCourse) => {
                if (!cou.hasOwnProperty('couId')) {
                    cou.couId = this.getUniqueID();
                }
            });
        }

        // do generate id for projects if not existing
        if (this.profile.accomplishments?.projects && this.profile.accomplishments?.projects.length > 0) {
            this.profile.accomplishments.projects.forEach((pro: ProfileProject) => {
                if (!pro.hasOwnProperty('proId')) {
                    pro.proId = this.getUniqueID();
                }
            });
        }

        // do generate id for publications if not existing
        if (this.profile.accomplishments?.publications && this.profile.accomplishments?.publications.length > 0) {
            this.profile.accomplishments.publications.forEach((pub: ProfilePublication) => {
                if (!pub.hasOwnProperty('pubId')) {
                    pub.pubId = this.getUniqueID();
                }
            });
        }



        this.profileOrig = Object.assign({}, this.profile);
        this.prepopulatefields();
    }
      
    get FREE(): Constants {
        return Constants.FREE;
    }

    get STANDARD(): Constants {
        return Constants.STANDARD;
    }

    get PREMIUM(): Constants {
        return Constants.PREMIUM;
    }

    public enableGPT(): boolean {
        // check first if the user is recruiter
        if (this.appUser && this.appUser.user_type === Constants.RECRUITER) {
            return true;
        } else {
            // just deal with the tokens. Even if subscription is active, its no use
            // if user used up all the tokens
            if (this.isClientValid()) {
                return true;
            } else {
                return false;
            }
        }

    }

    public showAddProject(): void {
        this.addProjectModal = true;
        this.projectAddMode = true;
        this.selectedProject = new ProfileProject();
    }

    public showAddEducation(): void {
        this.addEducationModal = true;
        this.educationAddMode = true;
        this.selectedEducation = new ProfileEducation();
    }

    public showAddPublication(): void {
        this.addPublicationModal = true;
        this.publicationAddMode = true;
        this.selectedPublication = new ProfilePublication();
    }

    public showAddCourse(): void {
        this.addCourseModal = true;
        this.courseAddMode = true;
        this.selectedCourse = new ProfileCourse();
    }

    public showAddExperience(): void {
        this.addExperienceModal = true;
        this.experienceAddMode = true;
        this.selectedExperience = new ProfileExperience();
    }

    public cancelShowAddProject(): void {
        this.addProjectModal = false;
        this.submitted = false;
        this.projectForm.clearValidators();
        this.projectForm.controls['description'].setValue("");
        this.projectForm.controls['title'].setValue("");
        this.projectForm.controls['url'].setValue("");

        this.startsAt = new Date();
        this.endsAt = new Date();
        this.current = false;
        this.hasStartsAt = true;
    }

    public cancelShowAddEducation(): void {
        this.addEducationModal = false;
        this.submitted = false;
        this.educationForm.clearValidators();
        this.educationForm.controls['activities_and_societies'].setValue("");
        this.educationForm.controls['degree_name'].setValue("");
        this.educationForm.controls['description'].setValue("");
        this.educationForm.controls['field_of_study'].setValue("");
        this.educationForm.controls['grade'].setValue("");
        this.educationForm.controls['school'].setValue("");
        this.educationForm.controls['school_linkedin_profile_url'].setValue("");

        this.startsAt = new Date();
        this.endsAt = new Date();
        this.current = false;
        this.hasStartsAt = true;
    }

   public cancelShowAddCourse(): void {
        this.addCourseModal = false;
        this.submitted = false;
        this.courseForm.clearValidators();
        this.courseForm.controls['name'].setValue("");
        this.courseForm.controls['number'].setValue("");
    }

   public cancelShowAddPublication(): void {
        this.addPublicationModal = false;
        this.submitted = false;
        this.publicationForm.clearValidators();
        this.publicationForm.controls['description'].setValue("");
        this.publicationForm.controls['name'].setValue("");
        this.publicationForm.controls['publisher'].setValue("");
        this.publicationForm.controls['url'].setValue("");

        this.published_on = new Date();
    }

    public cancelShowAddExperience(): void {
        this.addExperienceModal = false;
        this.submitted = false;
        this.experienceForm.clearValidators();
        this.experienceForm.controls['company'].setValue("");
        this.experienceForm.controls['title'].setValue("");
        this.experienceForm.controls['description'].setValue("");
        this.experienceForm.controls['location'].setValue("");
        this.experienceForm.controls['expId'].setValue("");
        this.experienceForm.controls['company_linkedin_profile_url'].setValue("");
        this.experienceForm.controls['company_linkedin_profile_url'].setErrors(null);

        this.startsAt = new Date();
        this.endsAt = new Date();
        this.current = false;
        this.hasStartsAt = true;
    }

    public confirmAddProject(): void {
        this.submitted = true;
        let valid = true;

        if (this.hasStartsAt) {
            // check if end date is greater than start date
            if (!this.current) {
                // means has end date
                if (this.startsAt >= this.endsAt) {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Start Date cannot be the same or greater than End Date`, life: 5000});
                    valid = false;
                }
            } else {
                const currentDate = new Date();
                if (this.startsAt > currentDate) {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `The Start Date cannot be after todays date`, life: 5000});
                    valid = false;
                } else {
                    valid = true;
                }
            }
        } else {
            // if no start date, theres no end date as well
            this.current = true; // this toggles to have NO end date
            valid = true;
        }

        if (this.projectForm.valid && valid) {
            this.addProjectModal = false;

            if (this.selectedProject && this.selectedProject.editMode) {
                console.log('EDIT MODE');
                // edit mode
                this.selectedProject.description = this.projectForm.controls['description'].value;
                this.selectedProject.title = this.projectForm.controls['title'].value;
                this.selectedProject.url = this.projectForm.controls['url'].value;

                if (this.hasStartsAt) {
                    this.selectedProject.starts_at = new ProfileDate();
                    this.selectedProject.starts_at.day = this.startsAt.getDate();
                    this.selectedProject.starts_at.month =  this.startsAt.getMonth() + 1;
                    this.selectedProject.starts_at.year = this.startsAt.getFullYear();


                    if (!this.current) {
                        // not current, should have end date
                        this.selectedProject.ends_at = new ProfileDate();
                        this.selectedProject.ends_at.day = this.endsAt.getDate();
                        this.selectedProject.ends_at.month =  this.endsAt.getMonth() + 1;
                        this.selectedProject.ends_at.year = this.endsAt.getFullYear();
                    } else {
                        this.selectedProject.ends_at = undefined;
                    }
                } else {
                    this.selectedProject.starts_at = undefined;
                    this.selectedProject.ends_at = undefined;
                }


                // find it on the list of education and replace it with this current one
                if (this.profile && this.profile.accomplishments && this.profile.accomplishments.projects) {
                    for (let i = 0; i < this.profile.accomplishments.projects.length; i++) {
                        let ex: ProfileProject = this.profile.accomplishments.projects[i];
                        if (ex.proId === this.selectedProject.proId) {
                            ex = this.selectedProject;

                            this.profile.projectChanged = true;
                            this.profile.hasChanged = true;
            
                            // reset the form
                            this.cancelShowAddProject();
                            break;
                        }
                    }
                }
            }  else {
                console.log('ADD MODE');

                // add
                // convert to object
                let pe: ProfileProject = new ProfileProject();

                pe.proId = this.getUniqueID();

                pe.description = this.projectForm.controls['description'].value;
                pe.title = this.projectForm.controls['title'].value;
                pe.url = this.projectForm.controls['url'].value;
                
                if (this.hasStartsAt) {
                    pe.starts_at = new ProfileDate();
                    pe.starts_at.day = this.startsAt.getDate();
                    pe.starts_at.month =  this.startsAt.getMonth() + 1;
                    pe.starts_at.year = this.startsAt.getFullYear();


                    if (!this.current) {
                        pe.ends_at = new ProfileDate();
                        pe.ends_at.day = this.endsAt.getDate();
                        pe.ends_at.month =  this.endsAt.getMonth() + 1;
                        pe.ends_at.year = this.endsAt.getFullYear();
                    } else {
                        pe.ends_at = undefined;
                    }
    
                } else {
                    pe.starts_at = undefined;
                }

                this.profile.accomplishments?.projects?.unshift(pe);
                this.profile.projectChanged = true;
                this.profile.hasChanged = true;

                // reset the form
                this.cancelShowAddProject();
            }

            if (this.profile && this.profile.accomplishments && this.profile.accomplishments.projects) {
                // do the sorting here
                this.profile.accomplishments.projects = XcvUtilsService.sortProject(this.profile.accomplishments.projects);
            }

        }
    }

    public confirmAddEducation(): void {
        this.submitted = true;
        let valid = true;

        if (this.hasStartsAt) {
            // check if end date is greater than start date
            if (!this.current) {
                // means has end date
                if (this.startsAt >= this.endsAt) {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Start Date cannot be the same or greater than End Date`, life: 5000});
                    valid = false;
                }
            } else {
                const currentDate = new Date();
                if (this.startsAt > currentDate) {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `The Start Date cannot be after todays date`, life: 5000});
                    valid = false;
                } else {
                    valid = true;
                }
            }
        } else {
            // if no start date, theres no end date as well
            this.current = true; // this toggles to have NO end date
            valid = true;
        }

        if (this.educationForm.valid && valid) {
            console.log('>>>> all valid');
            this.addEducationModal = false;

            if (this.selectedEducation && this.selectedEducation.editMode) {
                console.log('EDIT MODE');
                // edit mode
                this.selectedEducation.activities_and_societies = this.educationForm.controls['activities_and_societies'].value;
                this.selectedEducation.degree_name = this.educationForm.controls['degree_name'].value;
                this.selectedEducation.description = this.educationForm.controls['description'].value;
                this.selectedEducation.field_of_study = this.educationForm.controls['field_of_study'].value;
                this.selectedEducation.grade = this.educationForm.controls['grade'].value;
                this.selectedEducation.school = this.educationForm.controls['school'].value;
                this.selectedEducation.school_linkedin_profile_url = this.educationForm.controls['school_linkedin_profile_url'].value;

                if (this.hasStartsAt) {
                    this.selectedEducation.starts_at = new ProfileDate();
                    this.selectedEducation.starts_at.day = this.startsAt.getDate();
                    this.selectedEducation.starts_at.month =  this.startsAt.getMonth() + 1;
                    this.selectedEducation.starts_at.year = this.startsAt.getFullYear();


                    if (!this.current) {
                        // not current, should have end date
                        this.selectedEducation.ends_at = new ProfileDate();
                        this.selectedEducation.ends_at.day = this.endsAt.getDate();
                        this.selectedEducation.ends_at.month =  this.endsAt.getMonth() + 1;
                        this.selectedEducation.ends_at.year = this.endsAt.getFullYear();
                    } else {
                        this.selectedEducation.ends_at = undefined;
                    }
                } else {
                    this.selectedEducation.starts_at = undefined;
                    this.selectedEducation.ends_at = undefined;
                }


                // find it on the list of education and replace it with this current one
                if (this.profile && this.profile.education) {
                    for (let i = 0; i < this.profile.education.length; i++) {
                        let ex: ProfileEducation = this.profile.education[i];
                        if (ex.eduId === this.selectedEducation.eduId) {
                            ex = this.selectedEducation;

                            this.profile.educationChanged = true;
                            this.profile.hasChanged = true;
            
                            // reset the form
                            this.cancelShowAddEducation();
                            break;
                        }
                    }
                }
            }  else {
                console.log('ADD MODE');

                // add
                // convert to object
                let pe: ProfileEducation = new ProfileEducation();

                pe.eduId = this.getUniqueID();

                pe.activities_and_societies = this.educationForm.controls['activities_and_societies'].value;
                pe.degree_name = this.educationForm.controls['degree_name'].value;
                pe.description = this.educationForm.controls['description'].value;
                pe.field_of_study = this.educationForm.controls['field_of_study'].value;
                pe.grade = this.educationForm.controls['grade'].value;
                pe.school = this.educationForm.controls['school'].value;
                pe.school_linkedin_profile_url = this.educationForm.controls['school_linkedin_profile_url'].value;


                if (this.hasStartsAt) {
                    pe.starts_at = new ProfileDate();
                    pe.starts_at.day = this.startsAt.getDate();
                    pe.starts_at.month =  this.startsAt.getMonth() + 1;
                    pe.starts_at.year = this.startsAt.getFullYear();


                    if (!this.current) {
                        pe.ends_at = new ProfileDate();
                        pe.ends_at.day = this.endsAt.getDate();
                        pe.ends_at.month =  this.endsAt.getMonth() + 1;
                        pe.ends_at.year = this.endsAt.getFullYear();
                    } else {
                        pe.ends_at = undefined;
                    }
    
                } else {
                    pe.starts_at = undefined;
                }


                console.log('THE PE = ', pe);

                this.profile.education?.unshift(pe);

                this.profile.educationChanged = true;
                this.profile.hasChanged = true;

                // reset the form
                this.cancelShowAddEducation();
            }

            if (this.profile && this.profile.education) {
                // do the sorting here
                this.profile.education = XcvUtilsService.sortEducation(this.profile.education);
            }
        }
    }

    public confirmAddCourse(): void {
        this.submitted = true;
        if (this.courseForm.valid) {
            this.addCourseModal = false;

            if (this.selectedCourse && this.selectedCourse.editMode) {
                this.selectedCourse.number = this.courseForm.controls['number'].value;
                this.selectedCourse.name = this.courseForm.controls['name'].value;

                // find it on the list of courses and replace it with this current one
                if (this.profile && this.profile.accomplishments && this.profile.accomplishments.courses) {

                    for (let i = 0; i < this.profile.accomplishments.courses.length; i++) {
                        let cou: ProfileCourse = this.profile.accomplishments.courses[i];
                        if (cou.couId === this.selectedCourse.couId) {
                            cou = this.selectedCourse;
                            this.profile.accomplishments.coursesChanged = true;
                            this.profile.hasChanged = true;

                            // reset the form
                            this.cancelShowAddCourse();
                            break;
                        }
                    }
                }
            } else {
                // add mode
                // convert to object
                let cou: ProfileCourse = new ProfileCourse();

                cou.couId = this.getUniqueID();
                cou.name = this.courseForm.controls['name'].value;
                cou.number = this.courseForm.controls['number'].value;
                this.profile.accomplishments?.courses?.unshift(cou);

                if (this.profile.accomplishments) {
                    this.profile.accomplishments.coursesChanged = true;
                    this.profile.hasChanged = true;
                }

                // reset the form
                this.cancelShowAddCourse();
            }
        }
    }

    public confirmAddPublication(): void {
        this.submitted = true;
        let valid = true;

        if (this.hasPublishOn) {
            if (XcvUtilsService.isNotNullOrEmpty(this.published_on)) {
                const currentDate = new Date();
    
                if (this.published_on < currentDate ) {
                    valid = true;
                } else {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `The published date cannot be today, or after todays date`, life: 5000});
                    valid = false;
                }
            } else {
                valid = false;
            }
    

        } else {
            // no publish date, no validation
            valid = true;
        }

        if (this.publicationForm.valid && valid) {
            this.addPublicationModal = false;

            if (this.selectedPublication && this.selectedPublication.editMode) {
                // edit mode
                this.selectedPublication.description = this.publicationForm.controls['description'].value;
                this.selectedPublication.name = this.publicationForm.controls['name'].value;
                this.selectedPublication.publisher = this.publicationForm.controls['publisher'].value;
                this.selectedPublication.url = this.publicationForm.controls['url'].value;

                // if (XcvUtilsService.isNotNullOrEmpty(this.published_on)) {
                if (this.hasPublishOn) {
                    this.selectedPublication.published_on = new ProfileDate();
                    this.selectedPublication.published_on.day = this.published_on.getDate();
                    this.selectedPublication.published_on.month =  this.published_on.getMonth() + 1;
                    this.selectedPublication.published_on.year = this.published_on.getFullYear();
                } else {
                    delete this.selectedPublication.published_on;
                }

                // find it on the list of publications and replace it with this current one
                if (this.profile && this.profile.accomplishments && this.profile.accomplishments.publications) {
                    for (let i = 0; i < this.profile.accomplishments.publications.length; i++) {
                        let pub: ProfilePublication = this.profile.accomplishments.publications[i];

                        if (pub.pubId === this.selectedPublication.pubId) {
                            pub = this.selectedPublication;

                            this.profile.accomplishments.publicationsChanged = true;
                            this.profile.hasChanged = true;

                            // reset the form
                            this.cancelShowAddPublication();
                            break;
                        }
                    }
                                    // do sorting
                if (this.profile.accomplishments && this.profile.accomplishments.publications) {
                    XcvUtilsService.sortPublications(this.profile.accomplishments.publications, Constants.DESC);
                }

                }
            } else {
                // add mode
                // convert to object
                let pub: ProfilePublication = new ProfilePublication();

                pub.pubId = this.getUniqueID();

                pub.description = this.publicationForm.controls['description'].value;
                pub.name = this.publicationForm.controls['name'].value;
                pub.publisher = this.publicationForm.controls['publisher'].value;
                pub.url = this.publicationForm.controls['url'].value;


                if (this.hasPublishOn) {
                    pub.published_on = new ProfileDate();
                    pub.published_on.day = this.published_on.getDate();
                    pub.published_on.month =  this.published_on.getMonth() + 1;
                    pub.published_on.year = this.published_on.getFullYear();
                } else {
                    delete pub.published_on;
                }


                this.profile.accomplishments?.publications?.unshift(pub);

                if (this.profile.accomplishments && this.profile.accomplishments.publications) {
                    this.profile.accomplishments.publicationsChanged = true;
                    this.profile.hasChanged = true;
                }

                // reset the form
                this.cancelShowAddPublication();

                // do sorting
                if (this.profile.accomplishments && this.profile.accomplishments.publications) {
                    XcvUtilsService.sortPublications(this.profile.accomplishments.publications, Constants.DESC);
                }
            }
        }
    }

    public confirmAddExperience(): void {
        this.submitted = true;
        let datesValid = true;

        if (XcvUtilsService.isNotNullOrEmpty(this.startsAt)) {
            if (!this.current && !XcvUtilsService.isNotNullOrEmpty(this.endsAt)) {
                // if not current, end date is required
                datesValid = false;
            } else {
                datesValid = true;
            }
        } else {
            datesValid = false;
        }

        if (this.experienceForm.valid && datesValid) {
            this.addExperienceModal = false;

            if (this.selectedExperience && this.selectedExperience.editMode) {
                // edit mode
                this.selectedExperience.company = this.experienceForm.controls['company'].value;
                this.selectedExperience.title = this.experienceForm.controls['title'].value;
                this.selectedExperience.description = this.experienceForm.controls['description'].value;
                this.selectedExperience.location = this.experienceForm.controls['location'].value;
                this.selectedExperience.company_linkedin_profile_url = this.experienceForm.controls['company_linkedin_profile_url'].value;

                if (XcvUtilsService.isNotNullOrEmpty(this.startsAt)) {
                    this.selectedExperience.starts_at = new ProfileDate();
                    this.selectedExperience.starts_at.day = this.startsAt.getDate();
                    this.selectedExperience.starts_at.month =  this.startsAt.getMonth() + 1;
                    this.selectedExperience.starts_at.year = this.startsAt.getFullYear();
                }
                if (!this.current) {
                    this.selectedExperience.ends_at = new ProfileDate();
                    this.selectedExperience.ends_at.day = this.endsAt.getDate();
                    this.selectedExperience.ends_at.month =  this.endsAt.getMonth() + 1;
                    this.selectedExperience.ends_at.year = this.endsAt.getFullYear();
                }

                // find it on the list of experiences and replace it with this current one
                if (this.profile && this.profile.experience) {
                    for (let i = 0; i < this.profile.experience.length; i++) {
                        let ex: ProfileExperience = this.profile.experience[i];
                        if (ex.expId === this.selectedExperience.expId) {
                            ex = this.selectedExperience;

                            this.manualEditExperienceCacheCheckRoutine(ex);

                            this.profile.experienceChanged = true;
                            this.profile.hasChanged = true;
            
                            // reset the form
                            this.cancelShowAddExperience();
                            break;
                        }
                    }
                }
            } else {
                // add
                // convert to object
                let pe: ProfileExperience = new ProfileExperience();

                pe.expId = this.getUniqueID();

                pe.company = this.experienceForm.controls['company'].value;
                pe.title = this.experienceForm.controls['title'].value;
                pe.description = this.experienceForm.controls['description'].value;
                if (XcvUtilsService.isNotNullOrEmpty(this.experienceForm.controls['location'].value)) {
                    pe.location = this.experienceForm.controls['location'].value;
                } else {
                    pe.location = '';
                }

                if (XcvUtilsService.isNotNullOrEmpty(this.experienceForm.controls['company_linkedin_profile_url'].value)) {
                    pe.company_linkedin_profile_url = this.experienceForm.controls['company_linkedin_profile_url'].value;
                } else {
                    pe.company_linkedin_profile_url = '';
                }

                pe.starts_at = new ProfileDate();
                pe.starts_at.day = this.startsAt.getDate();
                pe.starts_at.month =  this.startsAt.getMonth() + 1;
                pe.starts_at.year = this.startsAt.getFullYear();

                if (!this.current) {
                    pe.ends_at = new ProfileDate();
                    pe.ends_at.day = this.endsAt.getDate();
                    pe.ends_at.month =  this.endsAt.getMonth() + 1;
                    pe.ends_at.year = this.endsAt.getFullYear();
                }

                this.profile.experience?.unshift(pe);

                this.profile.experienceChanged = true;
                this.profile.hasChanged = true;

                // reset the form
                this.cancelShowAddExperience();
            }

            if (this.profile && this.profile.experience) {
                // do the sorting here
                XcvUtilsService.sortExperience(this.profile.experience, Constants.DESC);
            }
        }
    }

    public initExperienceFormGroup(): void {
        this.experienceForm = this.fb.group({
            company: ['', [Validators.required]],
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            location: [''],
            company_linkedin_profile_url: ['',[DomainValidator()]],
            expId: [''],
            // startsAt: ['', [Validators.required]],
            // endsAt: ['']
        });
    }

    public initPublicationFormGroup(): void {
        this.publicationForm = this.fb.group({
            description: [''],
            name: ['', [Validators.required]],
            publisher: [''],
            url: ['', [Validators.required, DomainValidator()]],
            pubId: ['']
        });
    }


    public initCourseFormGroup(): void {
        this.courseForm = this.fb.group({
            name: ['', [Validators.required]],
            number: ['', [Validators.required]],
            corId: ['']
        });
    }

    public initEducationFormGroup(): void {
        this.educationForm = this.fb.group({
            degree_name: ['', [Validators.required]],
            field_of_study: ['', [Validators.required]],
            school: ['', [Validators.required]],
            activities_and_societies: [''],
            description: [''],
            grade: [''],
            school_linkedin_profile_url: ['',[DomainValidator()]],
            corId: ['']
        });
    }

    public initProjectFormGroup(): void {
        this.projectForm = this.fb.group({
            title: ['', [Validators.required]],
            url: ['',[DomainValidator()]],
            description: [''],
            proId: ['']
        });
    }

    public toggleEndsAt(): void {
        if (this.current === false) {
            this.current = true;
        } else {
            this.current = false;
        }
    }

    public isNotNullOrEmpty(value: any): boolean {
        return XcvUtilsService.isNotNullOrEmpty(value);
    }

    public getUniqueID() {
        return Math.floor(Math.random() * Date.now());
    }

    public removeProjectItem(): void {
        if (this.profile && this.profile.accomplishments && this.profile.accomplishments.projects  && 
            this.profile.accomplishments.projects.length > 0) {
            const newArray: ProfileProject[] = this.profile.accomplishments.projects.filter((pro: ProfileProject) => pro.proId !== this.selectedProject.proId);
            this.profile.accomplishments.projects = newArray;
            this.profile.projectChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public removeEducationItem(): void {
        if (this.profile && this.profile.education && this.profile.education.length > 0) {

            console.log('this.selectedEducation = ', this.selectedEducation);

            const newArray: ProfileEducation[] = this.profile.education.filter((edu: ProfileEducation) => edu.eduId !== this.selectedEducation.eduId);
            console.log('newArray = ', newArray);
            this.profile.education = newArray;
            this.profile.educationChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public removeCourseItem(): void {
        if (this.profile && this.profile.accomplishments && this.profile.accomplishments.courses && this.profile.accomplishments.courses.length > 0) {
            const newArray: ProfileCourse[] = this.profile.accomplishments.courses.filter((cou: ProfileCourse) => cou.couId !== this.selectedCourse.couId);
            this.profile.accomplishments.courses = newArray;
            this.profile.accomplishments.coursesChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public removePublicationItem(): void {
        if (this.profile && this.profile.accomplishments && this.profile.accomplishments.publications && this.profile.accomplishments.publications.length > 0) {
            const newArray: ProfilePublication[] = this.profile.accomplishments.publications.filter((pub: ProfilePublication) => pub.pubId !== this.selectedPublication.pubId);
            this.profile.accomplishments.publications = newArray;
            this.profile.accomplishments.publicationsChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public removeExperienceItem(): void {
        if (this.profile && this.profile.experience && this.profile.experience.length > 0) {
            const newArray: ProfileExperience[] = this.profile.experience.filter((obj: ProfileExperience) => obj.expId !== this.selectedExperience.expId);
            this.profile.experience = newArray;
            this.profile.experienceChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public confirmRemoveProject(selectedProject: ProfileProject) {
        this.selectedProject = selectedProject;

        this.confirmationService.confirm({
            message: `Are you sure that you want delete '${this.selectedProject.title}'?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.removeProjectItem();
            },
            key: "confirmDelProjectDialog"            
        });
    }

    public confirmRemoveEducation(selectedEducation: ProfileEducation) {
        this.selectedEducation = selectedEducation;

        this.confirmationService.confirm({
            message: `Are you sure that you want delete '${this.selectedEducation.degree_name}'?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.removeEducationItem();
            },
            key: "confirmDelEducationDialog"            
        });
    }
    
    public confirmRemoveCourse(selectedCourse: ProfileCourse) {
        this.selectedCourse = selectedCourse;

        this.confirmationService.confirm({
            message: `Are you sure that you want delete '${this.selectedCourse.name}'?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.removeCourseItem();
            },
            key: "confirmDelCourseDialog"            
        });

    }

    public confirmRemovePublication(selectedPublication: ProfilePublication) {
        this.selectedPublication = selectedPublication;

        console.log('PUBLICATION FOR DELETE = ', this.selectedPublication);

        this.confirmationService.confirm({
            message: `Are you sure that you want delete '${this.selectedPublication.name}'?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.removePublicationItem();
            },
            key: "confirmDelPublicationDialog"            
        });
    }

    public confirm(selectedExperience: ProfileExperience) {
        this.selectedExperience = selectedExperience;
        this.confirmationService.confirm({
            message: `Are you sure that you want delete ${this.selectedExperience.company} - ${this.selectedExperience.title}?`,
            accept: () => {
                //Actual logic to perform a confirmation
                this.removeExperienceItem();
            },
            key: "confirmDelExperienceDialog"            
        });
    }

    public editProject(selectedProject: ProfileProject) {
        this.selectedProject = selectedProject;
        this.projectAddMode = false;

        this.cancelShowAddProject();
        this.projectForm.controls['description'].setValue(this.selectedProject.description);
        this.projectForm.controls['title'].setValue(this.selectedProject.title);
        this.projectForm.controls['url'].setValue(this.selectedProject.url);



        if (XcvUtilsService.isNotNullOrEmpty(this.selectedProject.starts_at)) {
            let day = this.selectedProject.starts_at?.day;
            let month = this.selectedProject.starts_at?.month - 1;
            let year = this.selectedProject.starts_at?.year;
            this.startsAt = new Date(year, month, day);
            this.hasStartsAt = true;

            if (XcvUtilsService.isNotNullOrEmpty(this.selectedProject.ends_at)) {
                let day = this.selectedProject.ends_at?.day;
                let month = this.selectedProject.ends_at?.month - 1;
                let year = this.selectedProject.ends_at?.year;
                this.endsAt = new Date(year, month, day);
                this.current = false;
            } else {
                this.current = true;
            }
    
        } else {
            this.hasStartsAt = false;
        }

        this.selectedProject.editMode = true;
        this.addProjectModal = true;
    }

    // public editEduation(selectedEducation: ProfileEducation) {
    //     this.selectedEducation = selectedEducation;
    //     this.educationAddMode = false;

    //     this.cancelShowAddEducation();
    //     this.educationForm.controls['activities_and_societies'].setValue(this.selectedEducation.activities_and_societies);
    //     this.educationForm.controls['degree_name'].setValue(this.selectedEducation.degree_name);
    //     this.educationForm.controls['description'].setValue(this.selectedEducation.description);
    //     this.educationForm.controls['field_of_study'].setValue(this.selectedEducation.field_of_study);
    //     this.educationForm.controls['grade'].setValue(this.selectedEducation.grade);
    //     this.educationForm.controls['school'].setValue(this.selectedEducation.school);
    //     this.educationForm.controls['school_linkedin_profile_url'].setValue(this.selectedEducation.school_linkedin_profile_url);

    //     this.selectedEducation.editMode = true;
    //     this.addEducationModal = true;
    // }

    public editCourse(selectedCourse: ProfileCourse) {
        this.selectedCourse = selectedCourse;
        this.courseAddMode = false;

        // load it on the UI
        this.cancelShowAddCourse();

        this.courseForm.controls['name'].setValue(this.selectedCourse.name);
        this.courseForm.controls['number'].setValue(this.selectedCourse.number);

        this.selectedCourse.editMode = true;
        this.addCourseModal = true;
    }

    public editPublication(selectedPublication: ProfilePublication): void {
        this.selectedPublication = selectedPublication;
        this.publicationAddMode = false;

        // load it on the UI
        this.cancelShowAddPublication();

        this.publicationForm.controls['pubId'].setValue(this.selectedPublication.pubId);
        this.publicationForm.controls['description'].setValue(this.selectedPublication.description);
        this.publicationForm.controls['name'].setValue(this.selectedPublication.name);
        this.publicationForm.controls['publisher'].setValue(this.selectedPublication.publisher);
        this.publicationForm.controls['url'].setValue(this.selectedPublication.url);

        if (this.selectedPublication.published_on !== undefined ) {
            let day = this.selectedPublication.published_on?.day;
            let month = this.selectedPublication.published_on?.month - 1;
            let year = this.selectedPublication.published_on?.year;
            this.published_on = new Date(year, month, day);
            this.hasPublishOn = true;
        } else {
            this.hasPublishOn = false;
        }

        this.selectedPublication.editMode = true;
        this.addPublicationModal = true;
    }

    public editEducation(selectedEducation: ProfileEducation) {
        this.selectedEducation = selectedEducation;
        this.educationAddMode = false;

        // load it on the UI
        this.cancelShowAddEducation();
        this.educationForm.controls['activities_and_societies'].setValue(this.selectedEducation.activities_and_societies);
        this.educationForm.controls['degree_name'].setValue(this.selectedEducation.degree_name);
        this.educationForm.controls['description'].setValue(this.selectedEducation.description);
        this.educationForm.controls['field_of_study'].setValue(this.selectedEducation.field_of_study);
        this.educationForm.controls['grade'].setValue(this.selectedEducation.grade);
        this.educationForm.controls['school'].setValue(this.selectedEducation.school);
        this.educationForm.controls['school_linkedin_profile_url'].setValue(this.selectedEducation.school_linkedin_profile_url);

        if (XcvUtilsService.isNotNullOrEmpty(this.selectedEducation.starts_at)) {
            let day = this.selectedEducation.starts_at?.day;
            let month = this.selectedEducation.starts_at?.month - 1;
            let year = this.selectedEducation.starts_at?.year;
            this.startsAt = new Date(year, month, day);
            this.hasStartsAt = true;

            if (XcvUtilsService.isNotNullOrEmpty(this.selectedEducation.ends_at)) {
                let day = this.selectedEducation.ends_at?.day;
                let month = this.selectedEducation.ends_at?.month - 1;
                let year = this.selectedEducation.ends_at?.year;
                this.endsAt = new Date(year, month, day);
                this.current = false;
            } else {
                this.current = true;
            }
    
        } else {
            this.hasStartsAt = false;

        }


        this.selectedEducation.editMode = true;
        this.addEducationModal = true;
    }


    public editExperience(selectedExperience: ProfileExperience): void {
        this.selectedExperience = selectedExperience;
        this.experienceAddMode = false;

        // load it on the UI
        this.cancelShowAddExperience();
        this.experienceForm.controls['expId'].setValue(this.selectedExperience.expId);
        this.experienceForm.controls['company'].setValue(this.selectedExperience.company);
        this.experienceForm.controls['title'].setValue(this.selectedExperience.title);
        this.experienceForm.controls['description'].setValue(this.selectedExperience.description);

        if (this.selectedExperience.company_linkedin_profile_url) {
            this.experienceForm.controls['company_linkedin_profile_url'].setValue(this.selectedExperience.company_linkedin_profile_url);
        }

        if (XcvUtilsService.isNotNullOrEmpty(this.selectedExperience.location)) {
            this.experienceForm.controls['location'].setValue(this.selectedExperience.location);
        }

        if (XcvUtilsService.isNotNullOrEmpty(this.selectedExperience.starts_at)) {
            let day = this.selectedExperience.starts_at?.day;
            let month = this.selectedExperience.starts_at?.month - 1;
            let year = this.selectedExperience.starts_at?.year;
            this.startsAt = new Date(year, month, day);
        }

        if (XcvUtilsService.isNotNullOrEmpty(this.selectedExperience.ends_at)) {
            let day = this.selectedExperience.ends_at?.day;
            let month = this.selectedExperience.ends_at?.month - 1;
            let year = this.selectedExperience.ends_at?.year;
            this.endsAt = new Date(year, month, day);
            this.current = false;
        } else {
            this.current = true;
        }

        this.selectedExperience.editMode = true;
        this.addExperienceModal = true;
    }

    private checkScreenSize() {
        const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
        this.isSmallScreen = isSmallScreen;
        // You can also dynamically apply a CSS class if needed
        if (isSmallScreen) {
            this.displayDialog = false;
        } else {
            this.displayDialog = true;
        }
    }

    public addToAboutCache(aboutData: any): void {
        // add the newly created about data on the cache
        this.profile.aboutDataRedoArray = [];
        this.profile.aboutShowRedo = false;
        this.profile.aboutUndoLimit = Constants.GPT_CACHE_LIMIT;        

        if (!XcvUtilsService.isNotEmpty(this.profile.aboutDataUndoArray)) {
            this.profile.aboutDataUndoArray = [];
        }
        if (!XcvUtilsService.isNotEmpty(this.profile.aboutDataCurrentArray)) {
            this.profile.aboutDataCurrentArray = [];
        }


        if (this.profile.aboutDataCurrentArray && this.profile.aboutDataCurrentArray.length === 0) {
            this.profile.aboutDataCurrentArray.push(aboutData);    
        } else {
            if (this.profile.aboutDataUndoArray && this.profile.aboutDataUndoArray.length == this.profile.aboutUndoLimit) {
                this.messageService.add({ severity: 'info', summary: 'Info', detail: `You only have a limit of ${this.profile.aboutUndoLimit + 1} on the cache`, life: 5000});
                this.profile.aboutDataUndoArray.reverse().pop();
                this.profile.aboutDataUndoArray.reverse();
            }

            this.profile.aboutDataUndoArray.push(this.profile.aboutDataCurrentArray.pop());  
            this.profile.aboutDataCurrentArray.push(aboutData);
            this.profile.aboutShowUndo = true;
        }
    }
    
    public aboutUndo(): void {
        this.aboutUndoTriggered = true;
        this.profile.aboutShowRedo = true;
        if (this.profile.aboutDataUndoArray && this.profile.aboutDataUndoArray.length != 0) {    
            this.profile.aboutDataRedoArray.push(this.profile.aboutDataCurrentArray.pop());
            this.profile.aboutDataCurrentArray.push(this.profile.aboutDataUndoArray.pop());
            if (this.profile.aboutDataUndoArray.length == 0) {
                this.profile.aboutShowUndo = false;
            }
        }    

        // push the current on the profile.about
        this.profile.about = this.profile.aboutDataCurrentArray[0];
        this.profile.aboutChanged = true;
        this.profile.hasChanged = true;
    }

    public aboutRedo(): void {
        if (this.profile.aboutDataRedoArray && this.profile.aboutDataRedoArray.length != 0) {    
            this.profile.aboutDataUndoArray.push(this.profile.aboutDataCurrentArray.pop());
            this.profile.aboutDataCurrentArray.push(this.profile.aboutDataRedoArray.pop());
            if (this.profile.aboutDataRedoArray.length == 0) {
            this.profile.aboutShowRedo = false;
            }
        }

        if (this.profile.aboutDataUndoArray && this.profile.aboutDataUndoArray.length > 0) {
            this.profile.aboutShowUndo = true;
        } else {
            this.profile.aboutShowUndo = false;
        }

        // push the current on the profile.about
        this.profile.about = this.profile.aboutDataCurrentArray[0];
        this.profile.aboutChanged = true;
        this.profile.hasChanged = true;
    }

    public addToExpCache(aboutData: any, selectedExperience: ProfileExperience): void {
        // add the newly created about data on the cache

        if (!XcvUtilsService.isNotNullOrEmpty(selectedExperience.experienceGPTCache)) {
            selectedExperience.experienceGPTCache = new ProfileExperienceGPT();
            selectedExperience.experienceGPTCache.expDataRedoArray = [];
        } else if (selectedExperience.experienceGPTCache) {
            selectedExperience.experienceGPTCache.expDataRedoArray = [];
        }

        if (selectedExperience.experienceGPTCache) {
            selectedExperience.experienceGPTCache.expShowRedo = false;
            selectedExperience.experienceGPTCache.expShowRedo = false;
            selectedExperience.experienceGPTCache.expUndoLimit = Constants.GPT_CACHE_LIMIT;        

            if (!XcvUtilsService.isNotEmpty(selectedExperience.experienceGPTCache.expDataUndoArray)) {
                selectedExperience.experienceGPTCache.expDataUndoArray = [];
            }
    
            if (!XcvUtilsService.isNotEmpty(selectedExperience.experienceGPTCache.expDataCurrentArray)) {
                selectedExperience.experienceGPTCache.expDataCurrentArray = [];
            }
    
            if (selectedExperience.experienceGPTCache.expDataCurrentArray && selectedExperience.experienceGPTCache.expDataCurrentArray.length === 0) {
                selectedExperience.experienceGPTCache.expDataCurrentArray.push(aboutData);    
            } else {
                if (selectedExperience.experienceGPTCache.expDataUndoArray && selectedExperience.experienceGPTCache.expDataUndoArray.length == selectedExperience.experienceGPTCache.expUndoLimit) {
                    this.messageService.add({ severity: 'info', summary: 'Info', detail: `You only have a limit of ${selectedExperience.experienceGPTCache.expUndoLimit + 1} on the cache`, life: 5000});
                    selectedExperience.experienceGPTCache.expDataUndoArray.reverse().pop();
                    selectedExperience.experienceGPTCache.expDataUndoArray.reverse();
                }
    
                selectedExperience.experienceGPTCache.expDataUndoArray.push(selectedExperience.experienceGPTCache.expDataCurrentArray.pop());  
                selectedExperience.experienceGPTCache.expDataCurrentArray.push(aboutData);
                selectedExperience.experienceGPTCache.expShowUndo = true;

                // find this on list and update
                this.updateOnExp(selectedExperience);

            }   
        }
    }

    public expUndo(selectedExperience: ProfileExperience): void {
        if (selectedExperience.experienceGPTCache) {

            selectedExperience.experienceGPTCache.expShowRedo = true;

            if (selectedExperience.experienceGPTCache.expDataUndoArray && selectedExperience.experienceGPTCache.expDataUndoArray.length != 0) {    
                selectedExperience.experienceGPTCache.expDataRedoArray.push(selectedExperience.experienceGPTCache.expDataCurrentArray.pop());
                selectedExperience.experienceGPTCache.expDataCurrentArray.push(selectedExperience.experienceGPTCache.expDataUndoArray.pop());
                if (selectedExperience.experienceGPTCache.expDataUndoArray.length == 0) {
                    selectedExperience.experienceGPTCache.expShowUndo = false;
                }
            }    

            // push the current on the selectedExperience.description
            selectedExperience.description = selectedExperience.experienceGPTCache.expDataCurrentArray[0];
            this.profile.experienceChanged = true;
            this.profile.hasChanged = true;

            // find this on list and update
            this.updateOnExp(selectedExperience);
        }
    }

    public expRedo(selectedExperience: ProfileExperience): void {
        if (selectedExperience.experienceGPTCache) {

            if (selectedExperience.experienceGPTCache.expDataRedoArray && selectedExperience.experienceGPTCache.expDataRedoArray.length != 0) {    
                selectedExperience.experienceGPTCache.expDataUndoArray.push(selectedExperience.experienceGPTCache.expDataCurrentArray.pop());
                selectedExperience.experienceGPTCache.expDataCurrentArray.push(selectedExperience.experienceGPTCache.expDataRedoArray.pop());
                if (selectedExperience.experienceGPTCache.expDataRedoArray.length == 0) {
                selectedExperience.experienceGPTCache.expShowRedo = false;
                }
            }
    
            if (selectedExperience.experienceGPTCache.expDataUndoArray && selectedExperience.experienceGPTCache.expDataUndoArray.length > 0) {
                selectedExperience.experienceGPTCache.expShowUndo = true;
            } else {
                selectedExperience.experienceGPTCache.expShowUndo = false;
            }
    
            // push the current on the selectedExperience.description
            selectedExperience.description = selectedExperience.experienceGPTCache.expDataCurrentArray[0];
            this.profile.experienceChanged = true;
            this.profile.hasChanged = true;

            // find this on list and update
            this.updateOnExp(selectedExperience);
        }
    }

    private updateOnExp(selectedExperience: ProfileExperience): void {
        // find this on list and update
        if (this.profile.experience) {
            for (let i = 0; i < this.profile.experience.length; i++) {
                let pe: ProfileExperience = this.profile.experience[i];
                if (pe.expId === selectedExperience.expId) {
                    pe = selectedExperience;
                    break;
                }
            }
        }
    }

    public manualEditExperienceCacheCheckRoutine(ex: ProfileExperience): void {
        // check the new description of this, and try to find on the cache
        // if not existing, add on the cache
        let isUnique = true;
        if (ex.experienceGPTCache) {

            if (ex.experienceGPTCache.expDataCurrentArray) {
                for (let i = 0; i < ex.experienceGPTCache.expDataCurrentArray.length; i++) {
                    let expCache: string = ex.experienceGPTCache.expDataCurrentArray[i];
                    if (expCache === this.selectedExperience.description) {
                        isUnique = false;
                        break;
                    }
                }
            }

            if (ex.experienceGPTCache.expDataUndoArray) {
                for (let i = 0; i < ex.experienceGPTCache.expDataUndoArray.length; i++) {
                    let expCache: string = ex.experienceGPTCache.expDataUndoArray[i];
                    if (expCache === this.selectedExperience.description) {
                        isUnique = false;
                        break;
                    }
                }
            }

            if (ex.experienceGPTCache.expDataRedoArray) {
                for (let i = 0; i < ex.experienceGPTCache.expDataRedoArray.length; i++) {
                    let expCache: string = ex.experienceGPTCache.expDataRedoArray[i];
                    if (expCache === this.selectedExperience.description) {
                        isUnique = false;
                        break;
                    }
                }
            }

            if (isUnique) {
                // add it
                console.log('adding experence desc to cache = ', this.selectedExperience.description);
                this.addToExpCache(this.selectedExperience.description, this.selectedExperience);
            }
        }
    }

    private persistAboutCacheRoutine(response: Profile): void {
        // before resetting, get the cache for about
        if (XcvUtilsService.isNotEmpty(this.profile.aboutDataCurrentArray) ||
            XcvUtilsService.isNotEmpty(this.profile.aboutDataUndoArray) ||
            XcvUtilsService.isNotEmpty(this.profile.aboutDataRedoArray) 
        ) {
            response.aboutDataCurrentArray = Object.assign([], this.profile.aboutDataCurrentArray);
            response.aboutDataUndoArray = Object.assign([], this.profile.aboutDataUndoArray);
            response.aboutDataRedoArray = Object.assign([], this.profile.aboutDataRedoArray);
            response.aboutShowUndo = this.profile.aboutShowUndo;
            response.aboutShowRedo = this.profile.aboutShowRedo;
        }
    }

    private persistExperienceCacheRoutine(response: Profile): void {
        // reset the experence cache upon initial load
        if (this.initialLoadExperience) {
            if (response.experience) {
                response.experience.forEach((newexp: ProfileExperience) => {
                    if (newexp.experienceGPTCache) {
                        newexp.experienceGPTCache.expDataCurrentArray = [];
                        newexp.experienceGPTCache.expDataUndoArray = [];
                        newexp.experienceGPTCache.expDataRedoArray = [];
                        newexp.experienceGPTCache.expShowUndo = false;
                        newexp.experienceGPTCache.expShowRedo = false;
                    }
                }); 
            }
            this.initialLoadExperience = false;
        }

        // before resetting, get the cache for experience
        if (this.profile.experience && response.experience) {
            for (let i = 0; i < this.profile.experience.length; i++) {
                for (let j = 0; j < response.experience.length; j++) {

                    let oldExperience: ProfileExperience = this.profile.experience[i];
                    let newExperience: ProfileExperience = response.experience[j];

                    if (oldExperience.expId === newExperience.expId ) {
                        // assigning experiences cache to the newly saved record
                        console.log('>>> assigning experiences cache to the newly saved record');
                        newExperience.experienceGPTCache = oldExperience.experienceGPTCache;
                    }
                }
            }
        }
    }

    public getClientThenSaveOnStorage(): void {
        if (this.userType === Constants.CLIENT) {

            // get also the client info and put it on the storage
            let p = new AytHttpParams();
            p.set('vanity_name', this.vanity_name);
            this.subscription = this.userservice.getClient(p).subscribe({
                next: (response: Client) => {
                if (response) {
                    console.log('response client = ', response);
                    const data = {
                    date_subscribed: response.date_subscribed, 
                    first_name: response.first_name, 
                    last_name: response.last_name, 
                    tokens_consumed: response.tokens_consumed, 
                    tokens_limit: response.tokens_limit, 
                    unique_id: response.unique_id, 
                    vanity_name: response.vanity_name
                    };

                    this.localStorageService.saveData('userClient', data);
                    this.client = response;
                    // this triggers the header to get the lateset
                    this.dataService.setClient(this.client);

                    // this will be used on the parent if type is client
                    this.clientRetrievedEvent.emit('true');

                    // create the appUser and also store on storage
                    let au: AppUser = this.localStorageService.getData('appUser');
                    if (au) {
                        au.first_name = response.first_name;
                        au.last_name = response.last_name;
                        this.localStorageService.saveData('appUser', au);
                        this.dataService.setAppUser(au);
                    }



                }
                },
                error: error => {
                    XcvUtilsService.handleError(error, this.messageService);
                }
            });
        } else {
            // this will be used on the parent if type is client
            this.clientRetrievedEvent.emit('false');
        }
    }

    get TOOLTIP_DEFAULT_POSITION() {
        return Constants.TOOLTIP_DEFAULT_POSITION;
    }

    public isClientValid(): boolean {
        if (this.client) {
          let tokenLinit = Number(this.client.tokens_limit);
          let tokenConsumed = Number(this.client.tokens_consumed);
          if ( tokenConsumed < tokenLinit &&
            tokenLinit > 0
            ) {
            return true;
          } else {
            return false;
          }
        }
        return false;
    }
    
    public updateContacts(): void {
        this.showContactsModal = true;
    }

    public cancelEditContacts(): void {
        this.showContactsModal = false;
    }

    public removeEmail(): void {
        //do a copy
        const emailsCopy: any[]  = Object.assign([], this.emails);
        const idx = emailsCopy.findIndex(mail => this.selectedEmail === mail);
        
        if (emailsCopy && emailsCopy.length === 1) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `At least 1 email is required`, life: 5000});
        } else {
            // do this
            emailsCopy.splice(idx, 1);
            this.emails = [];
            this.emails = [...emailsCopy];
            this.newEmailArray = this.emails;

            // when removing, assign a new selected email
            this.selectedEmail = this.emails[0];
        }
    }

    public removePhone(): void {
        //do a copy
        const phonesCopy: any[]  = Object.assign([], this.phones);
        const idx = phonesCopy.findIndex(phone => this.selectedPhone === phone);
        if (phonesCopy && phonesCopy.length > 0) {
            // do this
            phonesCopy.splice(idx, 1);
            this.phones = [];
            this.phones = [...phonesCopy];
            this.newPhoneArray = this.phones;

            // when removing, assign a new selected email
            this.selectedPhone = this.phones[0];
        } else {
            this.selectedPhone = '';
        }
    }

    public addNewEmail(): void {
        // validate first the format
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (XcvUtilsService.isNotNullOrEmpty(this.newEmail)) {
            if (!emailRegex.test(this.newEmail)) {
                this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Invalid email format`, life: 5000});
            } else {
                // check if already existing before adding
                let existing = this.emails.find((e: string) => e.toLowerCase() === this.newEmail.toLowerCase());
                if (existing) {
                    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Email existing`, life: 5000});
                } else {
                    this.emails = [this.newEmail, ...this.emails];
                    this.newEmailArray = this.emails;
                    this.selectedEmail = this.emails[0];
                    this.newEmail = '';
                }
            }
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Please enter a valid email`, life: 5000});
        }
    }

    public addNewPhone(): void {
        if (XcvUtilsService.isNotNullOrEmpty(this.newPhone)) {
            // check if already existing before adding
            let existing = this.phones.find((e: string) => e.toLowerCase() === this.newPhone.toLowerCase());
            if (existing) {
                this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Phone existing`, life: 5000});
            } else {
                this.phones = [this.newPhone, ...this.phones];
                this.newPhoneArray = this.phones;
                this.selectedPhone = this.phones[0];
                this.newPhone = '';
            }
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Please enter phone number`, life: 5000});
        }
    }

    public confirmUpdateContacts(): void {
        if (this.selectedContactType.id === 'email') {
            this.emails = this.newEmailArray;
            this.contact.email = this.emails;

            this.profile.contactChanged = true;
            this.profile.hasChanged = true;
        } else if (this.selectedContactType.id === 'twitter') {

            if (this.contact.twitter) {
                this.contact.twitter = this.contact.twitter.trim();
            }

            console.log('this.contact.twitter = ', this.contact.twitter);
            this.profile.contactTwitterChanged = true;
            this.profile.hasChanged = true;


        } else if (this.selectedContactType.id === 'phone') {
            this.phones = this.newPhoneArray;
            this.contact.phone = this.phones;
            this.profile.contactPhoneChanged = true;
            this.profile.hasChanged = true;
        }
        this.selectedContactType = this.contactsTypeList[0];
        this.showContactsModal = false;
    }

    public selectContact(event: any): void {
        this.selectedContactType = event.value;
        if (this.selectedContactType.id === null) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Please select a contact type to edit`, life: 5000});
        } else {
            if (this.selectedContactType.id === 'email') {
                this.newEmail = '';
            } else if (this.selectedContactType.id === 'phone') {
                this.newPhone = '';
            } else if (this.selectedContactType.id === 'twitter') {

            }
        }
    }

    public selectEmail(): void {
        const newArray: string[] = this.emails.filter((item: string) => item !== this.selectedEmail);
        newArray.unshift(this.selectedEmail);
        this.newEmailArray = newArray;
    }

    public selectPhone(): void {
        const newArray: string[] = this.phones.filter((item: string) => item !== this.selectedPhone);
        newArray.unshift(this.selectedPhone);
        this.newPhoneArray = newArray;
    }

    public gotoJDAlign() {
        if (
            (this.userType === Constants.RECRUITER)
            ||
            (this.userType === Constants.CLIENT && this.appUser && 
            this.appUser.subscription && this.appUser.subscription.plan_type === Constants.PREMIUM)
            ) {
                if (this.title) {
                    this.router.navigate(['alignment'],{ queryParams: {'jdalign': true, 'title': this.title}})
                } else {
                    this.router.navigate(['alignment'],{ queryParams: {'jdalign': true}})
                }
        } else {
            this.router.navigate(['alignment'] );
        }
    }

    public sortExperience(): void {
        //for when its needed later
        if (this.profile && this.profile.experience) {
            if (this.experienceSort === Constants.DESC) {
                this.experienceSort = Constants.ASC;
            } else {
                this.experienceSort = Constants.DESC;
            }
            XcvUtilsService.sortExperience(this.profile.experience, this.experienceSort);
            this.profile.experienceChanged = true;
            this.profile.hasChanged = true;
        }
    }

    public getSubscriptionPlan() {
        // get subcriptionPlan from local storage
        // this will determine the toggle of what user can do here
        this.subcriptionPlan = this.localStorageService.getData('subcriptionPlan');
        if (this.subcriptionPlan) {
        console.log('subcriptionPlan = ', this.subcriptionPlan);
            // TODO VERIFY THESE AMOUNTS, THIS IS CRAZY
            if (
                this.subcriptionPlan.amount === 199
                ) {
                this.mode = Constants.STANDARD;
            } else if (
                this.subcriptionPlan.amount === 499
                ) {
                this.mode = Constants.PREMIUM;
            }
        }
    }

    sendMessage(msg: string) {
        this.messageEvent.emit(msg);
    }


    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([uri]);
        })
    }
    
    public navigateTo(path: string) {
        // store first the origin url before going
        let originUrl = {url: this.router.url};
        this.localStorageService.saveData('originUrl', originUrl);
        this.router.navigate([`${path}`] );
    }


    public openSelecTemplateModal() {
        this.showCarouselModal = true;
    }

    public selectTemplate(template: any) {


        // test, create store for selectedTemplate
        // what should happen here is that a popup dialog would appear containing a
        // gallery of templates, and when the user selects, the dialog wil close,
        // and it will go here to be saved on local storage and inform the parent component
        // what template was selected

        console.log('clicked select template = ', template.code);
        let selectedTemplate: any = {template: template.code};
        this.localStorageService.saveData('selectedTemplate', selectedTemplate);
        this.templateSelectedEvent.emit(template.code);

        // close the dialog
        this.showCarouselModal = false;
        this.templateSelected = true;
    }

    public removeTemplate() {
        this.localStorageService.removeData('selectedTemplate');
        this.templateRemoveEvent.emit('true');
        this.templateSelected = false;
    }

    public st() {
        // this will inform the parent that "save" was triggered
        // which in turn wound inform the child template
        // to reload

        // this.saveExecutedEvent.emit('true');
    }

    public togglePreviewSide() {
        // can only be toggled if the left side (template) is present
        let selectedTemplate = this.localStorageService.getData('selectedTemplate');
        if (selectedTemplate) {
            if (this.previewVisibleChild1) {
                this.previewVisibleChild1 = false;
                this.previewVisibleEvent.emit('false');
                console.log('emitting false..');
            } else {
                this.previewVisibleChild1 = true;
                this.previewVisibleEvent.emit('true');
                console.log('emitting true..');
            }
        }
    }

    public execTogglePublishOn() {
        if (this.hasPublishOn) {
            this.hasPublishOn = false;
        } else {
            this.hasPublishOn = true;
        }
    }

    public execToggleStartsAt() {
        if (this.hasStartsAt) {
            this.hasStartsAt = false;
            this.current = true; // just toggles for end date to NOT have a value
        } else {
            this.hasStartsAt = true;
        }
    }

    public openSectionsModal() {

        // <p-checkbox (change)="execTogglePublishOn()" [ngModelOptions]="{standalone: true}" [(ngModel)]="hasPublishOn" [binary]="true" inputId="hasPublishOn"></p-checkbox>
        // <label class="pl" for="current">Has Published Date</label>

        if (this.profile?.accomplishments?.coursesVisible) {
            this.coursesVisible = this.profile?.accomplishments?.coursesVisible;
        }
        if (this.profile?.accomplishments?.projectsVisible) {
            this.projectsVisible = this.profile?.accomplishments?.projectsVisible;
        }
        if (this.profile?.accomplishments?.publicationsVisible) {
            this.publicationsVisible = this.profile?.accomplishments?.publicationsVisible;
        }



        if ( this.profile?.visibility?.certifications === true ||
            this.profile?.visibility?.certifications === false) {
            this.certificationsVisible = this.profile?.visibility?.certifications;
        }

        if ( this.profile?.visibility?.experience === true ||
            this.profile?.visibility?.experience === false) {
            this.experienceVisible = this.profile?.visibility?.experience;
        }

        if ( this.profile?.visibility?.skills === true ||
            this.profile?.visibility?.skills === false) {
            this.skillsVisible = this.profile?.visibility?.skills;
        }
        
        if ( this.profile?.visibility?.education === true ||
            this.profile?.visibility?.education === false) {
            this.educationVisible = this.profile?.visibility?.education;
        }

        if ( this.profile?.visibility?.languages === true ||
            this.profile?.visibility?.languages === false) {
            this.languagesVisible = this.profile?.visibility?.languages;
        }

        if ( this.profile?.visibility?.email === true ||
            this.profile?.visibility?.email === false) {
            this.emailVisible = this.profile?.visibility?.email;
        }

        if ( this.profile?.visibility?.linkedin === true ||
            this.profile?.visibility?.linkedin === false) {
            this.lnkdinVisible = this.profile?.visibility?.linkedin;
        }

        if ( this.profile?.visibility?.contactno === true ||
            this.profile?.visibility?.contactno === false) {
            this.contactnoVisible = this.profile?.visibility?.contactno;
        }
        if ( this.profile?.visibility?.twitter === true ||
            this.profile?.visibility?.twitter === false) {
            this.twitterVisible = this.profile?.visibility?.twitter;
        }

        this.sectionsModal = true;
    }

    public toggleSection(section: string){
        console.log('section = ', section);

        switch(section) {
            case 'courses':
            if (this.profile.visibility && 
                (
                    this.profile.visibility.courses === true ||
                    this.profile.visibility.courses === false
                )
                ) {
                this.profile.visibility.courses = this.coursesVisible;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
                // if (this.profile.accomplishments && 
                //         (
                //             this.profile.accomplishments.coursesVisible === true ||
                //             this.profile.accomplishments.coursesVisible === false
                //         )
                //     ) {
                //     this.profile.accomplishments.coursesVisible = this.coursesVisible;
                //     console.log('coursesVisible = ', this.profile.accomplishments.coursesVisible);
                //     this.profile.accompishmentsChanged = true;
                //     this.profile.hasChanged = true;
                // }
            break;
            case 'projects':
            if (this.profile.visibility && 
                (
                    this.profile.visibility.projects === true ||
                    this.profile.visibility.projects === false
                )
                ) {
                this.profile.visibility.projects = this.projectsVisible;
                this.profile.visibilityChanged = true;
                this.profile.hasChanged = true;
            }
            break;
            case 'publications':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.publications === true ||
                        this.profile.visibility.publications === false
                    )
                    ) {
                    this.profile.visibility.publications = this.publicationsVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;
            case 'experience':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.experience === true ||
                        this.profile.visibility.experience === false
                    )
                    ) {
                    this.profile.visibility.experience = this.experienceVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;
            case 'skills':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.skills === true ||
                        this.profile.visibility.skills === false
                    )
                    ) {
                    this.profile.visibility.skills = this.skillsVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;
            case 'languages':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.languages === true ||
                        this.profile.visibility.languages === false
                    )
                    ) {
                    this.profile.visibility.languages = this.languagesVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;
            case 'education':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.education === true ||
                        this.profile.visibility.education === false
                    )
                    ) {
                    this.profile.visibility.education = this.educationVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;

            case 'certifications':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.certifications === true ||
                        this.profile.visibility.certifications === false
                    )
                    ) {
                    this.profile.visibility.certifications = this.certificationsVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;  
            case 'email':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.email === true ||
                        this.profile.visibility.email === false
                    )
                    ) {
                    this.profile.visibility.email = this.emailVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                    
                }
            break;
            case 'linkedin':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.linkedin === true ||
                        this.profile.visibility.linkedin === false
                    )
                    ) {
                    this.profile.visibility.linkedin = this.lnkdinVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                    
                }
            break;
            case 'contactno':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.contactno === true ||
                        this.profile.visibility.contactno === false
                    )
                    ) {
                    this.profile.visibility.contactno = this.contactnoVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                    
                }
            break;
            case 'twitter':
                if (this.profile.visibility && 
                    (
                        this.profile.visibility.twitter === true ||
                        this.profile.visibility.twitter === false
                    )
                    ) {
                    this.profile.visibility.twitter = this.twitterVisible;
                    this.profile.visibilityChanged = true;
                    this.profile.hasChanged = true;
                }
            break;
            default:
              // code block
          }
    }

    public closeSections() {
        console.log('closeSections executed');
        this.sectionsModal = false;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
    }
    
}
